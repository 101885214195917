import React from "react";
import OnlyWhenAuthorized from "../Auth/OnlyWhenAuthorized";
import { Route } from "react-router";
import PropTypes from "prop-types";

const AdvancedRoute = ({ accessType = "", ...otherProps }) => {
  if (accessType) {
    return (
      <OnlyWhenAuthorized requiredAccess={accessType}>
        <Route {...otherProps} />
      </OnlyWhenAuthorized>
    );
  } else {
    return <Route {...otherProps} />;
  }
};

AdvancedRoute.propTypes = {
  accessType: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AdvancedRoute;

import requestData from "./index";

export const loginUserApi = (email, password) => {
  const body = {
    login: {
      email_or_mobile: email,
      password,
    },
  };
  return requestData("/session", {
    method: "POST",
    body,
    isFormData: true,
    apiVersion: 2,
  });
};

export const sendOtp = (email) => {
  const body = {
    email,
  };
  return requestData("/send_otp_to_reset_password", {
    method: "POST",
    body,
    isFormData: true,
    apiVersion: 2,
  });
};

export const updatePassword = (email, token, password, confirmPassword) => {
  const body = {
    email,
    password: {
      password,
      password_confirmation: confirmPassword,
    },
    password_reset_token: token,
  };
  return requestData("/update_password", {
    method: "POST",
    body,
    isFormData: true,
    apiVersion: 2,
  });
};

export const userRoutes = {
  userManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      createUser: {
        path: "/create_user",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create User",
        drawerIcon: "fas fa-pencil-alt",
      },
      indexUser: {
        path: "/index_user",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Index User",
        drawerIcon: "fas fa-database",
      },

      createIsbUser: {
        path: "/create_isb_user",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Isb User",
        drawerIcon: "fas fa-pencil-alt",
      },

      indexIsbUser: {
        path: "/index_isb_user",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Index Isb User",
        drawerIcon: "fas fa-database",
      },
    },
  },
};

export const paymentRoutes = {
  paymentManagement: {
    accessType: ["super_admin"],
    routes: {
      capturePayment: {
        path: "/capture_payment",
        accessType: ["super_admin"],
        drawerTitle: "Capture Payment",
        drawerIcon: "fas fa-rupee-sign",
      },
    },
  },
};

import {
  GET_ANSWERED_PRIORITY_ENQUIRIES,
  GET_UN_ANSWERED_PRIORITY_ENQUIRIES,
  UPDATE_PRIORITY_ENQUIRY_STATUS,
  CHANGE_PRIORITY_TYPE,
} from "../Actions";
import { UN_ANSWERED_QUERY } from "../Constants";

const initialState = {
  dataIds: [],
  data: {},
  relation: {},
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
  onceFetched: false,
  currentPriorityType: UN_ANSWERED_QUERY,
};

const priorityEnquiry = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_ANSWERED_PRIORITY_ENQUIRIES}_SUCCESS`:
    case `${GET_UN_ANSWERED_PRIORITY_ENQUIRIES}_SUCCESS`: {
      const { data, dataIds, relation, paginationData } = payload;

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
      };
    }

    case `${UPDATE_PRIORITY_ENQUIRY_STATUS}_SUCCESS`: {
      const { isResolved } = action;
      const { data } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };

      if (store.currentPriorityType === UN_ANSWERED_QUERY && isResolved) {
        Reflect.deleteProperty(newData, data.id);
      }
      return {
        ...store,
        data: newData,
      };
    }

    case CHANGE_PRIORITY_TYPE: {
      const { value } = payload;
      return {
        ...store,
        currentPriorityType: value,
      };
    }

    case "CLEAR_PRIORITY_ENQUIRY_DATA": {
      return {
        dataIds: [],
        data: {},
        relation: {},
        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
        onceFetched: false,
      };
    }
    default:
      return store;
  }
};

export default priorityEnquiry;

import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import RouteList from "../Constants/Routes";
const AuthPage = lazy(() => import("../Pages/Auth"));
const ForgotPasswordPage = lazy(() => import("../Pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("../Pages/ResetPassword"));
const VersionPage = lazy(() => import("../Pages/Version"));

const Fallback = () => <p>Loading Page...</p>;
const Routes = RouteList.unAuthorized;

const UnAuthorizedRoutes = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Switch>
        <Route path={Routes.loginRoute.path} exact component={AuthPage} />
        <Route path={Routes.versionRoute.path} exact component={VersionPage} />
        <Route
          path={Routes.forgotPasswordRoute.path}
          exact
          component={ForgotPasswordPage}
        />
        <Route
          path={Routes.resetPasswordRoute.path}
          exact
          component={ResetPasswordPage}
        />
      </Switch>
    </Suspense>
  );
};

export default UnAuthorizedRoutes;

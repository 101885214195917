import React, { useEffect, useState, useCallback, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Icon from "../Icon";
import Button from "../Button";
import Tooltip from "../Tooltip";

import Version from "./Version";

import { getTodaysIsbRegistrationCount } from "../../ActionCreator/entries";

import LOGO from "../../Assets/logo.webp";
import styles from "./Navbar.module.css";

const Navbar = memo(
  ({ toggleDrawer, todaysRegistration, getTodaysIsbRegistrationCount }) => {
    const [isLoading, updateLoading] = useState(false);
    const syncTodaysRegistration = useCallback(async () => {
      try {
        updateLoading(true);
        await getTodaysIsbRegistrationCount();
        updateLoading(false);
      } catch (err) {
        updateLoading(false);
      }
    }, [getTodaysIsbRegistrationCount]);

    useEffect(() => {
      let timer = setTimeout(syncTodaysRegistration, 100);
      return () => clearTimeout(timer);
    }, [syncTodaysRegistration]);

    return (
      <nav className={styles.container}>
        <div className={styles.btn_section}>
          <button onClick={toggleDrawer} className={styles.toggle_btn}>
            <Icon name="fas fa-bars" />
          </button>
        </div>

        <div className={styles.nav_logo_box}>
          <Tooltip text="India's Super Brain Logo">
            <img
              className={styles.logo}
              alt="India's super Brain logo"
              src={LOGO}
            />
          </Tooltip>
          <span style={{ fontWeight: "bolder", paddingLeft: 10 }}>
            India's Super Brain Admin Pannel
          </span>
          <Version />
        </div>

        <div className={styles.data_box}>
          <span>
            Today's registration count -
            <span className={styles.count}>{todaysRegistration}</span>
          </span>

          <Tooltip text="Get Today's Registration Count">
            <Button
              className={styles.btn}
              size="auto"
              disabled={isLoading}
              loading={isLoading}
              onClick={syncTodaysRegistration}
            >
              <Icon name="fas fa-sync-alt" />
            </Button>
          </Tooltip>
        </div>
      </nav>
    );
  }
);

const mapStateToProps = (store, props) => {
  return {
    todaysRegistration: store.entries.todaysIsbRegistrations,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTodaysIsbRegistrationCount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

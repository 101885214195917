import {
  GET_CERTIFICATES_BY_RANK,
  UPDATE_RANK_CERTIFICATE_META_DATA,
} from "../../Actions";

const initialState = {
  dataIds: [],
  data: {},
  relation: {},

  onceFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },

  rankRange: {
    from: "1",
    to: "100",
  },
  selectedSubject: "total",
};

const viewCertificateByRank = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_CERTIFICATES_BY_RANK}_SUCCESS`: {
      const {
        data,
        relation,
        dataIds,

        paginationData,
      } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        onceFetched: true,
      };
    }

    case UPDATE_RANK_CERTIFICATE_META_DATA: {
      const { startRank, endRank, subject } = payload;
      return {
        ...store,
        selectedSubject: subject,
        rankRange: {
          from: startRank,
          to: endRank,
        },
      };
    }

    case "CLEAR_VIEW_CERTIFICATE_BY_RANK_DATA": {
      return {
        ...store,
        data: {},
        relation: {},
        dataIds: [],

        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
      };
    }

    default:
      return store;
  }
};

export default viewCertificateByRank;

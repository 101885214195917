import { GET_USER_DETAILS_TO_UPDATE } from "../Actions";

const initialState = {
  isDataFetched: false,
  canUpdateUserData: false,
  userData: {},
  currentSearchValue: "",
};

const updateUser = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_USER_DETAILS_TO_UPDATE}_SUCCESS`: {
      const { data = [], included: relations = [] } = payload;

      let haveEnrollmentId = false;
      let registrationData = {};
      let userData = {};

      //  Picking up the last enrollment id to update
      for (let i = relations.length - 1; i >= 0; i--) {
        const isRegistrationType = relations[i].type === "isbRegistrations";
        if (!isRegistrationType) {
          continue;
        }

        const item = relations[i];

        if (item && item.attributes && item.attributes.enrollmentId) {
          haveEnrollmentId = true;
          registrationData = {
            id: item.id,
            enrollemntId: item.attributes.enrollmentId,
          };
          break;
        }
      }

      if (haveEnrollmentId) {
        //  checking if last enrollment id is present in the data relationship
        const item = data.find((item) => {
          if (
            item.relationships &&
            item.relationships.isbRegistrations.data &&
            Array.isArray(item.relationships.isbRegistrations.data)
          ) {
            return item.relationships.isbRegistrations.data.find(
              (data) => data.id === registrationData.id
            );
          }
          return false;
        });

        userData = {
          id: item.id,
          ...(item.attributes || {}),
        };
      }

      return {
        ...store,
        userData,
        isDataFetched: true,
        canUpdateUserData: haveEnrollmentId,
      };
    }

    case `CLEAR_UPDATE_USER_DATA`: {
      return {
        ...store,
        canUpdateUserData: false,
        isDataFetched: false,
        userData: {},
      };
    }

    case `UPDATE_UPDATE_USER_SEARCH_VALUE`: {
      const { searchValue } = payload;

      return {
        ...store,
        currentSearchValue: searchValue,
      };
    }
    default:
      return store;
  }
};

export default updateUser;

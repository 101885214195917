import {
  GET_UNREGISTERED_SEMINAR_PARTICIPANT_COUNT,
  GET_UN_REGISTERED_SEMINAR_PARTICIPANTS,
} from "../../Actions";
import { createInitialPageStore, createInitialStore } from "../../helpers";

const initialStore = createInitialStore({ totalCount: null });

export const unRegisteredSeminar = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_UN_REGISTERED_SEMINAR_PARTICIPANTS}_SUCCESS`: {
      return createInitialPageStore(payload, store);
    }

    case `${GET_UNREGISTERED_SEMINAR_PARTICIPANT_COUNT}_SUCCESS`: {
      const { total_count } = payload;
      return { ...store, totalCount: total_count };
    }

    default:
      return store;
  }
};

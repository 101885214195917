import React, { memo } from "react";
import PropTypes from "prop-types";

const Icon = memo(({ name, className, ...otherProps }) => {
  return <i {...otherProps} className={`${name} ${className}`}></i>;
});

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};


export default Icon;

export const GET_REGISTERED_SEMINAR_PARTICIPANTS =
  "GET_REGISTERED_SEMINAR_PARTICIPANTS";
export const GET_UN_REGISTERED_SEMINAR_PARTICIPANTS =
  "GET_UN_REGISTERED_SEMINAR_PARTICIPANTS";
export const GET_REGISTERED_SEMINAR_PARTICIPANT_COUNT =
  "GET_REGISTERED_SEMINAR_PARTICIPANT_COUNT";
export const GET_UNREGISTERED_SEMINAR_PARTICIPANT_COUNT =
  "GET_UNREGISTERED_SEMINAR_PARTICIPANT_COUNT";
export const DOWNLOAD_REGISTERED_SEMINAR_PARTICIPANT_CSV =
  "DOWNLOAD_REGISTERED_SEMINAR_PARTICIPANT_CSV";
export const DOWNLOAD_UNREGISTERED_SEMINAR_PARTICIPANT_CSV =
  "DOWNLOAD_UNREGISTERED_SEMINAR_PARTICIPANT_CSV";

import COURSE_REACT from "../Assets/certificate/react_course.png";
import COURSE_CS from "../Assets/certificate/cs_course.png";
import COURSE_ML from "../Assets/certificate/course_ml.png";
import COURSE_DBMS from "../Assets/certificate/course_dbms.png";

import COURSE_IOT from "../Assets/certificate/iot_course.png";
import COURSE_ALGO from "../Assets/certificate/algo_course.png";
import COURSE_GAME from "../Assets/certificate/gamedev_course.png";

import REACT_RIGHT_SECTION_IMG from "../Assets/certificate/top25/react_right.png";
import CS_RIGHT_SECTION_IMG from "../Assets/certificate/top25/cs_right.png";
import ML_RIGHT_SECTION_IMG from "../Assets/certificate/top25/ml_right.png";
import DBMS_RIGHT_SECTION_IMG from "../Assets/certificate/top25/dbms_right.png";

import REACT_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/react_250_right.png";
import CS_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/cs_250_right.png";
import ML_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/ml_250_right.png";
import DBMS_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/dbms_250_right.png";

import CS_PARTICIPATION_RIGHT from "../Assets/certificate/participation/cs_right.png";
import REACT_PARTICIPATION_RIGHT from "../Assets/certificate/participation/react_right.png";
import ML_PARTICIPATION_RIGHT from "../Assets/certificate/participation/ml_right.png";
import DBMS_PARTICIPATION_RIGHT from "../Assets/certificate/participation/dbms_right.png";

import Algo_RIGHT_SECTION_IMG_25 from "../Assets/certificate/top25/algo_right.png";
import IOT_RIGHT_SECTION_IMG_25 from "../Assets/certificate/top25/iot_right.png";
import Game_RIGHT_SECTION_IMG_25 from "../Assets/certificate/top25/game_right.png";

import Algo_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/algo_250_right.png";
import IOT_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/iot_250_right.png";
import Game_RIGHT_SECTION_IMG_250 from "../Assets/certificate/top250/gamedev_250_right.png";

import Algo_PARTICIPATION_RIGHT from "../Assets/certificate/participation/algo_right.png";
import IOT_PARTICIPATION_RIGHT from "../Assets/certificate/participation/iot_right.png";
import Game_PARTICIPATION_RIGHT from "../Assets/certificate/participation/game_right.png";

export const subjects_2020 = {
  react: {
    name: "React",
    courseImage: COURSE_REACT,
    rightSectionImage: {
      top_25: REACT_RIGHT_SECTION_IMG,
      top_250: REACT_RIGHT_SECTION_IMG_250,
      participation: REACT_PARTICIPATION_RIGHT,
    },
  },
  cs: {
    name: "CS",
    courseImage: COURSE_CS,
    rightSectionImage: {
      top_25: CS_RIGHT_SECTION_IMG,
      top_250: CS_RIGHT_SECTION_IMG_250,
      participation: CS_PARTICIPATION_RIGHT,
    },
  },
  ml: {
    name: "ML",
    courseImage: COURSE_ML,
    rightSectionImage: {
      top_25: ML_RIGHT_SECTION_IMG,
      top_250: ML_RIGHT_SECTION_IMG_250,
      participation: ML_PARTICIPATION_RIGHT,
    },
  },
  dbms: {
    name: "DBMS",
    courseImage: COURSE_DBMS,
    rightSectionImage: {
      top_25: DBMS_RIGHT_SECTION_IMG,
      top_250: DBMS_RIGHT_SECTION_IMG_250,
      participation: DBMS_PARTICIPATION_RIGHT,
    },
  },
};

export const subjects_2021 = {
  ioTml: {
    name: "IOT ML",
    courseImage: COURSE_IOT,
    rightSectionImage: {
      top_25: IOT_RIGHT_SECTION_IMG_25,
      top_250: IOT_RIGHT_SECTION_IMG_250,
      participation: IOT_PARTICIPATION_RIGHT,
    },
  },
  gameDevelopment: {
    name: "Game Development",
    courseImage: COURSE_GAME,
    rightSectionImage: {
      top_25: Game_RIGHT_SECTION_IMG_25,
      top_250: Game_RIGHT_SECTION_IMG_250,
      participation: Game_PARTICIPATION_RIGHT,
    },
  },
  dsAlgo: {
    name: "DS ALGO",
    courseImage: COURSE_ALGO,
    rightSectionImage: {
      top_25: Algo_RIGHT_SECTION_IMG_25,
      top_250: Algo_RIGHT_SECTION_IMG_250,
      participation: Algo_PARTICIPATION_RIGHT,
    },
  },
};

export const createSubjectOptions = (subjects) => {
  return Object.keys(subjects).map((key) => ({
    name: subjects[key].name,
    value: key,
  }));
};

const createAllOptions = (options) => {
  return [...options, { name: "TOTAL", value: "total" }];
};

export const subjects = {
  2020: subjects_2020,
  2021: subjects_2021,
};

export const SubjectsOptions = createSubjectOptions(subjects["2021"]);
export const AllSubjectOptions = createAllOptions(SubjectsOptions);

// export const SubjectsOptions_2021 = [
//   { name: "IOT WITH ML", value: "IoTML" },
//   { name: "GAME DEVELOPMENT", value: "GameDevelopment" },
//   { name: "DS AND ALGO", value: "DSAlgo" },
// ];

export const supportRoutes = {
  supportManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      priorityEnquiry: {
        path: "/priority_enquiry",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Priority Enquiry",
        drawerIcon: "fas fa-sort-amount-up",
      },

      enquiry: {
        path: "/enquiry",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "Enquiry",
        drawerIcon: "fas fa-binoculars",
      },

      callbackRequests: {
        path: "/callback_requests",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Callback Requests",
        drawerIcon: "fas fa-headset",
      },

      interactWithUs: {
        path: "/interact_with_us",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Interact With Us",
        drawerIcon: "fas fa-headset",
      },

      updateUser: {
        path: "/update_user",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Update User Details",
        drawerIcon: "fas fa-user-edit",
      },

      visitorResponse: {
        path: "/visitor_response",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Visitor Response",
        drawerIcon: "fas fa-comment-dots",
      },
    },
  },
};

import { GET_STUDENT_CERTIFICATE_LINKS } from "../../Actions";

const initialState = {
  dataIds: [],
  data: {},
  relation: {},
};

const sendCertificate = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_STUDENT_CERTIFICATE_LINKS}_SUCCESS`: {
      const { data, relation, dataIds } = payload;

      return {
        ...store,
        data,
        dataIds,
        relation,
      };
    }

    default:
      return store;
  }
};

export default sendCertificate;

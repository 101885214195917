import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";

import Drawer from "../Components/Drawer";
import styles from "./Routes.module.css";
import Navbar from "../Components/Navbar";
import useDrawer from "../hooks/useDrawer";

import Routes from "../Constants/Routes";
import AdvancedRoute from "../Components/AdvancedRoute";

import { RootRoute } from "./AuthRoutes";

const searchManagement = Routes.authorized.searchManagement.routes;
const participantManagement = Routes.authorized.participantManagement.routes;
const ambassadorManagement = Routes.authorized.ambassadorManagement.routes;
const certificateManagement = Routes.authorized.certificateManagement.routes;

const leaderboardManagement = Routes.authorized.leaderboardManagement.routes;
const userManagement = Routes.authorized.userManagement.routes;
const mailManagement = Routes.authorized.mailManagement.routes;
const notificationManagement = Routes.authorized.notificationManagement.routes;
const refundsManagement = Routes.authorized.refundsManagement.routes;
const studyMaterialManagement =
  Routes.authorized.studyMaterialManagement.routes;

const supportManagement = Routes.authorized.supportManagement.routes;
const voucherManagement = Routes.authorized.voucherManagement.routes;
const paymentManagement = Routes.authorized.paymentManagement.routes;

const Fallback = () => <p>Loading Page ...</p>;

const GeneralSearchPage = lazy(() => import("../Pages/Search/GeneralSearch"));
const SearchByPage = lazy(() => import("../Pages/Search/SearchBy"));

const DatabasePage = lazy(() => import("../Pages/Database"));
const CapturePaymentPage = lazy(() => import("../Pages/CapturePayment"));

const UpdateUserPage = lazy(() => import("../Pages/UpdateUser"));

const EnquiryPage = lazy(() => import("../Pages/Enquiry"));

const InteractWithUsPage = lazy(() => import("../Pages/InteractWithUs"));
const WelcomeNotificationPage = lazy(() =>
  import("../Pages/Notification/WelcomeNotification")
);
const MailNotificationPage = lazy(() =>
  import("../Pages/Notification/MailerNotification")
);
const SmsNotificationPage = lazy(() =>
  import("../Pages/Notification/SmsNotification")
);

const UnregisteredParticipantsPage = lazy(() =>
  import("../Pages/UnregisteredParticipants")
);
const DailyReportsPage = lazy(() => import("../Pages/DailyReports"));

const FailedRegistrationsPage = lazy(() =>
  import("../Pages/FailedRegistrations")
);

const CallbackRequestPage = lazy(() => import("../Pages/CallbackRequest"));

const ViewRefundRegistrationPage = lazy(() =>
  import("../Pages/RefundRegistration/View")
);
const CreateRefundRegistrationPage = lazy(() =>
  import("../Pages/RefundRegistration/Create")
);

const CreateCampusAmbassadorPage = lazy(() =>
  import("../Pages/CampusAmbassador/CreateAmbassador")
);

const CampusAmbassadorPage = lazy(() =>
  import("../Pages/CampusAmbassador/ViewAmbassador")
);
const ChartsPage = lazy(() => import("../Pages/Charts"));

const SendMailPage = lazy(() => import("../Pages/Mail/SendMail"));
const EditMailPage = lazy(() => import("../Pages/Mail/EditMail"));
const CreateMailPage = lazy(() => import("../Pages/Mail/CreateMail"));
const RegisterEmailPage = lazy(() => import("../Pages/Mail/RegisterEmail"));
const EmailReportsPage = lazy(() => import("../Pages/Mail/ViewReports"));
const unsubscribeMailPage = lazy(() => import("../Pages/Mail/UnsubscribeMail"));

const CreateUserPage = lazy(() => import("../Pages/User/CreateUser"));
const IndexUserPage = lazy(() => import("../Pages/User/IndexUser"));
const CreateIsbUserPage = lazy(() => import("../Pages/User/CreateIsbUser"));
const IndexIsbUserPage = lazy(() => import("../Pages/User/IndexIsbUser"));

const UsedVouchersPage = lazy(() => import("../Pages/Vouchers/UsedVouchers"));
const LeftVouchersPage = lazy(() => import("../Pages/Vouchers/LeftVouchers"));
const DueVouchersPage = lazy(() => import("../Pages/Vouchers/DueVouchers"));
const CreateVoucherPage = lazy(() => import("../Pages/Vouchers/CreateVoucher"));

const VisitorResponsePage = lazy(() => import("../Pages/VisitorResponse"));

const CreateSubjectPage = lazy(() =>
  import("../Pages/StudyMaterial/Subject/Create")
);
const EditSubjectPage = lazy(() =>
  import("../Pages/StudyMaterial/Subject/Edit")
);

const CreateLecturePage = lazy(() =>
  import("../Pages/StudyMaterial/Lecture/Create")
);
const EditLecturePage = lazy(() =>
  import("../Pages/StudyMaterial/Lecture/Edit")
);

const CreateLectureMaterialPage = lazy(() =>
  import("../Pages/StudyMaterial/Materials/Create")
);
const EditLectureMaterialPage = lazy(() =>
  import("../Pages/StudyMaterial/Materials/Edit")
);

const PriorityEnquiryPage = lazy(() => import("../Pages/PriorityEnquiry"));

const LeaderBoardCreatePage = lazy(() => import("../Pages/LeaderBoard/Create"));

const LeaderBoardScorePage = lazy(() => import("../Pages/LeaderBoard/View"));

const CreateCertificatePage = lazy(() => import("../Pages/Certificate/Create"));
const SendCertificatePage = lazy(() => import("../Pages/Certificate/Send"));

// const ViewCertificatePage = lazy(() => import("../Pages/Certificate/View"));

const ViewCertificateByRankPage = lazy(() =>
  import("../Pages/Certificate/ViewByRank")
);

const AuthorizedRoutes = () => {
  const { drawerActive, toggleDrawer } = useDrawer();
  return (
    <div className={styles.auth_container}>
      <Navbar toggleDrawer={toggleDrawer} />

      <Drawer drawerActive={drawerActive} toggleDrawer={toggleDrawer} />

      <div className={styles.auth_content}>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <AdvancedRoute
              exact
              path={searchManagement.search.path}
              accessType={searchManagement.search.accessType}
              component={GeneralSearchPage}
            />

            <AdvancedRoute
              exact
              path={searchManagement.searchBy.path}
              accessType={searchManagement.searchBy.accessType}
              component={SearchByPage}
            />

            <AdvancedRoute
              exact
              path={supportManagement.enquiry.path}
              accessType={supportManagement.enquiry.accessType}
              component={EnquiryPage}
            />

            <AdvancedRoute
              exact
              path={supportManagement.priorityEnquiry.path}
              accessType={supportManagement.priorityEnquiry.accessType}
              component={PriorityEnquiryPage}
            />

            <AdvancedRoute
              exact
              path={supportManagement.interactWithUs.path}
              accessType={supportManagement.interactWithUs.accessType}
              component={InteractWithUsPage}
            />

            <AdvancedRoute
              exact
              path={supportManagement.callbackRequests.path}
              accessType={supportManagement.callbackRequests.accessType}
              component={CallbackRequestPage}
            />

            <AdvancedRoute
              exact
              path={supportManagement.updateUser.path}
              accessType={supportManagement.updateUser.accessType}
              component={UpdateUserPage}
            />

            <AdvancedRoute
              exact
              path={supportManagement.visitorResponse.path}
              accessType={supportManagement.visitorResponse.accessType}
              component={VisitorResponsePage}
            />

            <AdvancedRoute
              exact
              path={participantManagement.charts.path}
              accessType={participantManagement.charts.accessType}
              component={ChartsPage}
            />

            <AdvancedRoute
              exact
              path={participantManagement.unregisteredParticipants.path}
              accessType={
                participantManagement.unregisteredParticipants.accessType
              }
              component={UnregisteredParticipantsPage}
            />

            <AdvancedRoute
              exact
              path={participantManagement.dailyReports.path}
              accessType={participantManagement.dailyReports.accessType}
              component={DailyReportsPage}
            />

            <AdvancedRoute
              exact
              path={participantManagement.failedRegistrations.path}
              accessType={participantManagement.failedRegistrations.accessType}
              component={FailedRegistrationsPage}
            />

            <AdvancedRoute
              exact
              path={participantManagement.database.path}
              accessType={participantManagement.database.accessType}
              component={DatabasePage}
            />

            <AdvancedRoute
              exact
              path={ambassadorManagement.createCapmusAmbassador.path}
              accessType={
                ambassadorManagement.createCapmusAmbassador.accessType
              }
              component={CreateCampusAmbassadorPage}
            />

            <AdvancedRoute
              exact
              path={ambassadorManagement.viewCapmusAmbassador.path}
              accessType={ambassadorManagement.viewCapmusAmbassador.accessType}
              component={CampusAmbassadorPage}
            />

            <AdvancedRoute
              exact
              path={studyMaterialManagement.createSubject.path}
              accessType={studyMaterialManagement.createSubject.accessType}
              component={CreateSubjectPage}
            />

            <AdvancedRoute
              exact
              path={studyMaterialManagement.editSubject.path}
              accessType={studyMaterialManagement.editSubject.accessType}
              component={EditSubjectPage}
            />

            <AdvancedRoute
              exact
              path={studyMaterialManagement.createLecture.path}
              accessType={studyMaterialManagement.createLecture.accessType}
              component={CreateLecturePage}
            />

            <AdvancedRoute
              exact
              path={studyMaterialManagement.editLecture.path}
              accessType={studyMaterialManagement.editLecture.accessType}
              component={EditLecturePage}
            />

            <AdvancedRoute
              exact
              path={studyMaterialManagement.createLectureMaterial.path}
              accessType={
                studyMaterialManagement.createLectureMaterial.accessType
              }
              component={CreateLectureMaterialPage}
            />

            <AdvancedRoute
              exact
              path={studyMaterialManagement.editLectureMaterial.path}
              accessType={
                studyMaterialManagement.editLectureMaterial.accessType
              }
              component={EditLectureMaterialPage}
            />

            <AdvancedRoute
              exact
              path={notificationManagement.sendWelcomeNotification.path}
              accessType={
                notificationManagement.sendWelcomeNotification.accessType
              }
              component={WelcomeNotificationPage}
            />

            <AdvancedRoute
              exact
              path={notificationManagement.sendMailNotification.path}
              accessType={
                notificationManagement.sendMailNotification.accessType
              }
              component={MailNotificationPage}
            />

            <AdvancedRoute
              exact
              path={notificationManagement.sendsendSmsNotificationMail.path}
              accessType={
                notificationManagement.sendsendSmsNotificationMail.accessType
              }
              component={SmsNotificationPage}
            />

            <AdvancedRoute
              exact
              path={refundsManagement.createRefundRegistration.path}
              accessType={refundsManagement.createRefundRegistration.accessType}
              component={CreateRefundRegistrationPage}
            />

            <AdvancedRoute
              exact
              path={refundsManagement.refundRegistration.path}
              accessType={refundsManagement.refundRegistration.accessType}
              component={ViewRefundRegistrationPage}
            />

            <AdvancedRoute
              exact
              path={userManagement.createUser.path}
              accessType={userManagement.createUser.accessType}
              component={CreateUserPage}
            />

            <AdvancedRoute
              exact
              path={userManagement.indexUser.path}
              accessType={userManagement.indexUser.accessType}
              component={IndexUserPage}
            />

            <AdvancedRoute
              exact
              path={userManagement.createIsbUser.path}
              accessType={userManagement.createIsbUser.accessType}
              component={CreateIsbUserPage}
            />

            <AdvancedRoute
              exact
              path={userManagement.indexIsbUser.path}
              accessType={userManagement.indexIsbUser.accessType}
              component={IndexIsbUserPage}
            />

            <AdvancedRoute
              exact
              path={mailManagement.createMail.path}
              accessType={mailManagement.createMail.accessType}
              component={CreateMailPage}
            />

            <AdvancedRoute
              exact
              path={mailManagement.editMail.path}
              accessType={mailManagement.editMail.accessType}
              component={EditMailPage}
            />

            <AdvancedRoute
              exact
              path={mailManagement.sendMail.path}
              accessType={mailManagement.sendMail.accessType}
              component={SendMailPage}
            />
            <AdvancedRoute
              exact
              path={mailManagement.registerMail.path}
              accessType={mailManagement.registerMail.accessType}
              component={RegisterEmailPage}
            />
            <AdvancedRoute
              exact
              path={mailManagement.emailReports.path}
              accessType={mailManagement.emailReports.accessType}
              component={EmailReportsPage}
            />
            <AdvancedRoute
              exact
              path={mailManagement.unsubscribeMail.path}
              accessType={mailManagement.unsubscribeMail.accessType}
              component={unsubscribeMailPage}
            />

            <AdvancedRoute
              exact
              path={voucherManagement.dueVouchers.path}
              accessType={voucherManagement.dueVouchers.accessType}
              component={DueVouchersPage}
            />

            <AdvancedRoute
              exact
              path={voucherManagement.leftVouchers.path}
              accessType={voucherManagement.leftVouchers.accessType}
              component={LeftVouchersPage}
            />

            <AdvancedRoute
              exact
              path={voucherManagement.usedVouchers.path}
              accessType={voucherManagement.usedVouchers.accessType}
              component={UsedVouchersPage}
            />

            <AdvancedRoute
              exact
              path={voucherManagement.createVoucher.path}
              accessType={voucherManagement.createVoucher.accessType}
              component={CreateVoucherPage}
            />

            <AdvancedRoute
              exact
              path={leaderboardManagement.leaderboardCreateScore.path}
              accessType={
                leaderboardManagement.leaderboardCreateScore.accessType
              }
              component={LeaderBoardCreatePage}
            />

            <AdvancedRoute
              exact
              path={leaderboardManagement.leaderboardScores.path}
              accessType={leaderboardManagement.leaderboardScores.accessType}
              component={LeaderBoardScorePage}
            />

            <AdvancedRoute
              exact
              path={certificateManagement.createCertificate.path}
              accessType={certificateManagement.createCertificate.accessType}
              component={CreateCertificatePage}
            />

            <AdvancedRoute
              exact
              path={certificateManagement.sendCertificate.path}
              accessType={certificateManagement.sendCertificate.accessType}
              component={SendCertificatePage}
            />

            {/* <AdvancedRoute
              exact
              path={certificateManagement.viewCertificate.path}
              accessType={certificateManagement.viewCertificate.accessType}
              component={ViewCertificatePage}
            /> */}

            <AdvancedRoute
              exact
              path={certificateManagement.viewCertificateByRank.path}
              accessType={
                certificateManagement.viewCertificateByRank.accessType
              }
              component={ViewCertificateByRankPage}
            />

            <AdvancedRoute
              exact
              path={paymentManagement.capturePayment.path}
              accessType={paymentManagement.capturePayment.accessType}
              component={CapturePaymentPage}
            />
            <RootRoute />
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

export default AuthorizedRoutes;

export const leaderboardRoutes = {
  leaderboardManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      leaderboardCreateScore: {
        path: "/leaderboard_create_score",
        accessType: ["super_admin"],
        drawerTitle: "Create Score",
        drawerIcon: "fas fa-pencil-alt",
      },
      leaderboardScores: {
        path: "/leaderboard_scores",
        accessType: ["admin", "super_admin"],
        drawerTitle: "View Score",
        drawerIcon: "fas fa-eye",
      },
    },
  },
};

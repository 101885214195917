let API_URL = "http://localhost:3000/";
const DEFAULT_PATH = "api";
const DEFAULT_VERSION = "v1";
const DEFAULT_TYPE = "admin";
if (process.env.NODE_ENV === "production") {
  API_URL = "https://brianemon-india-super-brain.herokuapp.com/";
  // API_URL = "https://brianemon-staging.herokuapp.com/";
}
export const generateServerUrl = ({ version = "", type = "" }) => {
  return `${API_URL}/${DEFAULT_PATH}/${"v" + version || DEFAULT_VERSION}/${
    type || DEFAULT_TYPE
  }`;
};
export const DEFAULT_SERVER_URL = `${API_URL}/${DEFAULT_PATH}/${DEFAULT_VERSION}/${DEFAULT_TYPE}`;
import { GET_VISITOR_RESPONSE } from "../Actions";

const initialState = {
  data: {},
  relation: {},
  dataIds: [],

  onceFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const visitorResponse = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_VISITOR_RESPONSE}_SUCCESS`: {
      const {
        data,
        relation,
        dataIds,

        paginationData,
      } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,

        paginationData,
        onceFetched: true,
      };
    }

    default:
      return store;
  }
};

export default visitorResponse;

import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";

import styles from "./Button.module.css";
import ActivityIndicator from "../ActivityIndicator";

const Button = React.forwardRef(
  (
    {
      children,
      loading = false,
      primary = false,
      secondary = false,
      size = "medium",
      className,
      permanentDisabled = false,
      disabled,
      ...otherProps
    },
    ref
  ) => {
    const btnStyle = useMemo(() => {
      let style = ``;
      if (primary) {
        style += ` ${styles.primary}`;
      }

      if (secondary) {
        style += ` ${styles.secondary}`;
      }

      if (permanentDisabled) {
        style += ` ${styles.disabled}`;
      }

      return style;
    }, [primary, secondary]);

    const indicatorColor = useMemo(() => {
      if (primary) {
        return "white";
      }

      if (secondary) {
        return "#007bff";
      }
    }, [primary, secondary]);

    const sizeClass = useMemo(() => {
      switch (size) {
        case "small":
          return styles.sm;
        case "medium":
          return styles.md;
        case "large":
          return styles.lg;
        case "auto":
          return styles.at;
        default:
          return styles.md;
      }
    }, [size]);

    return (
      <button
        {...otherProps}
        ref={ref}
        disabled={disabled || permanentDisabled}
        className={`${styles.container} ${className} ${btnStyle} ${sizeClass} rc`}
      >
        {loading ? (
          <ActivityIndicator color={indicatorColor} size="s" />
        ) : (
          children
        )}
      </button>
    );
  }
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large", "auto"]),
  permanentDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;

import { GET_USER_RECORDS, UPDATE_USER_DATA, CREATE_USER } from "../../Actions";

const initialState = {
  totalUsers: null,
  data: {},
  dataIds: [],
  relation: {},
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const user = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_USER_RECORDS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        onceFetched: true,
      };
    }

    case `${UPDATE_USER_DATA}_SUCCESS`: {
      const { data } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      return {
        ...store,
        data: newData,
      };
    }

    case `${CREATE_USER}_SUCCESS`: {
      const { data } = payload;
      const newIds = [...store.dataIds, data.id];
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    default:
      return store;
  }
};

export default user;

import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import styles from "./Tooltip.module.css";

const Tooltip = ({ children, text, position = "bottom" }) => {
  const childProps = useRef(null);
  const tooltipRef = useRef(null);
  const timerRef = useRef(null);

  const [tooltipPos, updatePosition] = useState({
    top: 0,
    left: 0,
    tooltipPosition: "",
  });
  const { dimesnions } = useWindowDimensions();
  const [tooltipActive, updateStatus] = useState(false);

  const activateTooltip = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      return;
    }
    timerRef.current = setTimeout(() => {
      updateStatus(true);
    }, 500);
  }, []);

  const deActivateTooltip = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    updateStatus(false);
  }, []);

  const calcPosition = useCallback(
    (childWidth, childHeight, childTop, childLeft) => {
      const { width, height } = tooltipRef.current.getBoundingClientRect();

      let left = 0;
      let top = 0;
      let tooltipPosition = "";
      if (position === "bottom") {
        top = childTop + childHeight + 5;
        //  expanded in right
        if (childLeft + width >= dimesnions.width) {
          left = childLeft + childWidth - width;
          tooltipPosition = "right";
        } else if (childLeft - width < 0) {
          left = childLeft;
          tooltipPosition = "left";
        } else {
          left = childLeft + childWidth / 2 - width / 2;
          tooltipPosition = "middle";
        }
      }

      if (position === "top") {
        top = childTop - height - 5;
        //  expanded in right
        if (childLeft + width > dimesnions.width) {
          left = childLeft + childWidth - width;
          tooltipPosition = "right";
        } else if (childLeft - width < 0) {
          left = childLeft;
          tooltipPosition = "left";
        } else {
          left = childLeft + childWidth / 2 - width / 2;
          tooltipPosition = "middle";
        }
      }

      updatePosition({
        top,
        left,
        tooltipPosition,
      });
    },
    [dimesnions, position]
  );

  const onMouseEnterRegion = useCallback(
    (e) => {
      const { target } = e;
      const { width, height, top, left } = target.getBoundingClientRect();
      calcPosition(width, height, top, left);
      activateTooltip();
    },
    [calcPosition]
  );

  const { top, left, tooltipPosition } = tooltipPos;

  const getTriangleClass = useCallback(
    (isTop) => {
      switch (tooltipPosition) {
        case "left":
          return isTop ? styles.top_left : styles.bottom_left;
        case "right":
          return isTop ? styles.top_right : styles.bottom_right;
        case "middle":
          return isTop ? styles.top_middle : styles.bottom_middle;

        default:
          return "";
      }
    },
    [tooltipPosition]
  );

  const trianglePosClass = useMemo(() => {
    switch (position) {
      case "top":
        return getTriangleClass(false);
      case "bottom":
        return getTriangleClass(true);
      default:
        return "";
    }
  }, [position, getTriangleClass]);

  const Child = useMemo(() => {
    if (React.Children.count(children) > 1) {
      throw new Error(
        "More than one children found wrapped in the Tooltip component, only one children is required"
      );
    }

    childProps.current = {
      ...children.props,
      onMouseEnter: onMouseEnterRegion,
      onMouseLeave: deActivateTooltip,
    };
    return React.cloneElement(children, childProps.current);
  }, [children, onMouseEnterRegion]);

  return (
    <>
      {Child}
      <div
        style={{
          top: `${top}px`,
          left: `${left}px`,
          visibility: tooltipActive ? "visible" : "hidden",
          zIndex: tooltipActive ? "200000" : "-1",
        }}
        ref={tooltipRef}
        className={`${styles.tool_cont} ${
          tooltipActive ? styles.visible_anim : ""
        }`}
      >
        <div className={`${styles.traingle} ${trianglePosClass}`} />
        <span className={styles.tool_text}>{text}</span>
      </div>
    </>
  );
};

export default Tooltip;

import { LOGOUT_USER, UPDATE_CURRENT_ISB_VERSION } from "../Actions";
import rootReducer from "./rootReducer";

const createIsbVersionReducer = () => {
  let storeVersionData = {};

  return (store, action) => {
    const { type } = action;
    if (type === LOGOUT_USER) {
      store = undefined;
      storeVersionData = {};
    }

    //  handles store data for Differen ISB Version
    if (type === UPDATE_CURRENT_ISB_VERSION) {
      const { payload } = action;

      if (store.auth.currentIsbData && store.auth.currentIsbData.id) {
        storeVersionData[store.auth.currentIsbData.id] = { ...store };
      }

      const { versionId } = payload;

      if (Object.keys(storeVersionData).length > 0) {
        if (storeVersionData[versionId]) {
          store = { ...storeVersionData[versionId] };
        } else {
          const newStore = {};
          const whiteList = ["auth", "appData", "alert"];
          whiteList.forEach((reducerName) => {
            newStore[reducerName] = { ...store[reducerName] };
          });
          store = { ...newStore };
        }
      }
    }

    return rootReducer(store, action);
  };
};

const appReducer = createIsbVersionReducer();

export default appReducer;

import {
  DELETE_EMAIL_REPORTS,
  GET_DAILY_EMAIL_REPORT_COUNT,
  GET_EMAIL_REPORTS,
  SEARCH_EMAIL_REPORTS,
  UPDATE_FETCH_EMAIL_REPORTS_FLAG,
} from "../Actions";
import { updateDataOnDelete } from "../helpers/reducer";

const initialStore = {
  totalReportCount: null,
  data: {},
  relation: {},
  dataIds: [],
  onceDataFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const mailReport = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${SEARCH_EMAIL_REPORTS}_SUCCESS`:
    case `${GET_EMAIL_REPORTS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
      };
    }

    case `${GET_DAILY_EMAIL_REPORT_COUNT}_SUCCESS`: {
      const { total_count } = payload;
      return {
        ...store,
        totalReportCount: total_count,
      };
    }

    case UPDATE_FETCH_EMAIL_REPORTS_FLAG: {
      return {
        ...store,
        onceFetched: false,
      };
    }

    case `${DELETE_EMAIL_REPORTS}_SUCCESS`: {
      const { fromId, toId } = action;
      const { dataIds } = store;
      let newStore = { ...store };
      for (let i = fromId; i <= toId; i++) {
        const idx = String(i);
        if (dataIds.includes(idx)) {
          newStore = updateDataOnDelete(newStore, idx);
        }
      }
      return { ...newStore };
    }

    default: {
      return store;
    }
  }
};

export default mailReport;

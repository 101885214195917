import React from "react";
import OnlyWhenAuthorized from "../Auth/OnlyWhenAuthorized";
import Collapse from "../Collapse";
import DrawerItem from "./DrawerItem";
import PropTypes from "prop-types";

const DrawerTab = ({
  title,
  currentPath,
  icon,
  active,
  children,
  accessType,
}) => {
  return (
    <OnlyWhenAuthorized requiredAccess={accessType}>
      <Collapse
        shouldBeCollapsed={active}
        header={
          <DrawerItem
            isLink={false}
            currentPath={currentPath}
            title={title}
            icon={icon}
          />
        }
      >
        {children}
      </Collapse>
    </OnlyWhenAuthorized>
  );
};

DrawerTab.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  accessType: PropTypes.arrayOf(PropTypes.oneOf(["admin", "super_admin"]))
    .isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};


export default DrawerTab;

import {
  GET_UNREGISTERED_PARTICIPANTS,
  GET_UNREGISTERED_USER,
  GET_UNREGISTERED_PARTICIPANTS_COUNT,
  SET_PREV_TABLE_DATA,
} from "../Actions";

const initialState = {
  totalUnregisteredCount: null,
  uniqueUnregisteredCount: null,
  dataIds: [],
  data: {},
  relation: {},
  onceDataFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },

  prevTableData: {},
};

const unregisteredParticipants = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_UNREGISTERED_PARTICIPANTS}_SUCCESS`:
    case `${GET_UNREGISTERED_USER}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      const isInitiallyEmpty = store.dataIds.length === 0;
      let prevTableData = {};
      if (isInitiallyEmpty) {
        prevTableData = {
          data,
          relation,
          dataIds,
          paginationData,
        };
      } else {
        prevTableData = {
          data: store.data,
          relation: store.relation,
          dataIds: store.dataIds,
          paginationData: store.paginationData,
        };
      }

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        prevTableData,
        onceDataFetched: true,
      };
    }

    case `${GET_UNREGISTERED_PARTICIPANTS_COUNT}_SUCCESS`: {
      const { total_count, unique_count } = payload;
      return {
        ...store,
        totalUnregisteredCount: total_count,
        uniqueUnregisteredCount: unique_count,
      };
    }

    case SET_PREV_TABLE_DATA: {
      return {
        ...store,
        prevTableData: {
          data: store.data,
          relation: store.relation,
          dataIds: store.dataIds,
          paginationData: store.paginationData,
        },
        ...store.prevTableData,
      };
    }

    case `CLEAR_UNREGISTERED_PARTICIPANTS_DATA`: {
      return {
        ...store,
        dataIds: [],
        data: {},
        relation: {},
        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
      };
    }

    default:
      return store;
  }
};

export default unregisteredParticipants;

import React, { memo } from "react";
import PropTypes from "prop-types";

// import Icon from "../Icon";

import styles from "./Drawer.module.css";

const DrawerHead = memo(({ userName, email }) => {
  return (
    <div className={styles.head}>
      {/* <div className={styles.headerIcon_section}>
        <div className={`${styles.userIcon_box} rc`}>
          <Icon name="fas fa-user" />
        </div>
      </div> */}
      <div className={styles.header_desc}>
        <span>{userName}</span>
        <span className={styles.head_email}>{email}</span>
      </div>
    </div>
  );
});

DrawerHead.propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default DrawerHead;

import { useSelector } from "react-redux";
import { getAccessType } from "../../Selectors/auth";
import PropTypes from "prop-types";

const OnlyWhenAuthorized = ({ children, requiredAccess = "" }) => {
  const userAccessType = useSelector(getAccessType);
  let requiredTypes = [];
  if (typeof requiredAccess === "string") {
    requiredTypes = [requiredAccess];
  }
  if (Array.isArray(requiredAccess)) {
    requiredTypes = [...requiredAccess];
  }

  return requiredTypes.includes(userAccessType) ? children : null;
};

OnlyWhenAuthorized.propTypes = {
  requiredAccess: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
};

export default OnlyWhenAuthorized;

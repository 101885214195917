import { generateServerUrl } from "../config.local";
import { TOKEN_LOCAL_NAME, CURRENT_ISB_DATA } from "../Constants";
import { getGlobalIsbField } from "../GlobalData";

const convertObjectToFormData = (object, formData, prevKey) => {
  Object.keys(object).forEach((key) => {
    const item = object[key];
    const isFile = Object.prototype.toString.call(item) === "[object File]";
    const newKey = prevKey ? `${prevKey}[${key}]` : `${key}`;
    if (Array.isArray(item)) {
      appendArrayToFormData(formData, item, newKey);
    } else if (!isFile && typeof item === "object") {
      convertObjectToFormData(item, formData, newKey);
    } else {
      formData.append(newKey, item);
    }
  });
};

const appendArrayToFormData = (formData, array = [], key) => {
  const newKey = `${key}[]`;

  if (array.length === 0) {
    formData.append(`${key}`, "");
  } else {
    array.forEach((item) => {
      const isFile = Object.prototype.toString.call(item) === "[object File]";
      if (Array.isArray(item)) {
        appendArrayToFormData(formData, item, newKey);
      } else if (!isFile && typeof item === "object") {
        convertObjectToFormData(item, formData, newKey);
      } else {
        formData.append(newKey, item);
      }
    });
  }
};

const generateVersionUrl = (url) => {
  let newUrl = `${url}`;
  const params = new URL(url).searchParams;
  const haveSearchParams = params.toString() !== "";
  const currentIsbData = JSON.parse(
    window.sessionStorage.getItem(CURRENT_ISB_DATA)
  );

  if (haveSearchParams) {
    newUrl += `&isb_version=${currentIsbData.version}`;
  } else {
    newUrl += `?isb_version=${currentIsbData.version}`;
  }
  return newUrl;
};

const isUserBlockedToUseApi = (blackListedAccess) => {
  if (blackListedAccess.length === 0 || !Array.isArray(blackListedAccess)) {
    return false;
  }

  const userData = getGlobalIsbField("userDetails");
  if (blackListedAccess.includes(userData.accessType)) {
    return true;
  }
  return false;
};

const requestData = (url, options) => {
  const {
    body = {},
    isFormData,
    isBlob = false,
    apiType = "",
    apiVersion = "",
    method,
    blacklistAccessType = [],
    ...otherOptions
  } = options;

  if (isUserBlockedToUseApi(blacklistAccessType)) {
    return Promise.resolve({ urlBlocked: true });
  }

  let modifiedUrl = `${generateServerUrl({
    version: apiVersion,
    type: apiType,
  })}${url}`;

  const newOptions = {
    ...otherOptions,
    method,
    headers: {},
  };

  if (method === "POST") {
    newOptions.body = JSON.stringify(body);
  }

  if (isFormData) {
    const formData = new FormData();
    // Object.keys(body).forEach((id) => {
    //   if (Array.isArray(body[id])) {
    //     appendArrayToFormData(formData, body[id], id);
    //   } else if (typeof body[id] === "object") {
    //     Object.keys(body[id]).forEach((key) => {
    //       if (Array.isArray(body[id][key])) {
    //         appendArrayToFormData(formData, body[id][key], `${id}[${key}]`);
    //       } else {
    //         formData.append(`${id}[${key}]`, body[id][key]);
    //       }
    //     });
    //   } else {
    //     formData.append(`${id}`, body[id]);
    //   }
    // });
    convertObjectToFormData(body, formData, "");
    newOptions.body = formData;
  }

  const token = window.sessionStorage.getItem(TOKEN_LOCAL_NAME);
  if (token) {
    newOptions.headers = {
      ...newOptions.headers,
      Authorization: `Bearer ${token}`,
    };
    modifiedUrl = generateVersionUrl(modifiedUrl);
  }

  return fetch(modifiedUrl, newOptions)
    .then((res) => {
      if (res.status >= 400 || res.status >= 500) {
        return res.json();
      } else if (isBlob) {
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then((response) => {
      if (isBlob) {
        return { blobUrl: URL.createObjectURL(response) };
      }

      if (response.errors || response.error) {
        if (typeof response.error === "string") {
          throw response.error;
        }

        if (typeof response.errors === "string") {
          throw response.errors;
        }

        throw response.meta && response.meta.message
          ? response.meta.message
          : "Something went wrong";
      }

      return response;
    });

  // return (
  //   new Promise((res) => {
  //     setTimeout(() => {
  //       res({ loggedIn: true });
  //     }, 5000);
  //   })
  //     // .then((res) => res.json())
  //     .then((response) => {
  //       if (response.errors) {
  //         throw response.errors;
  //       }

  //       return response;
  //     })
  // );
};

export default requestData;

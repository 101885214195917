import { GET_CAMPUS_AMBASSADORS, CREATE_CAMPUS_AMBASSADOR } from "../Actions";

import { createRelationIterator } from "../helpers/middleware";
const initialState = {
  totalRegistrations: null,
  data: {},
  relation: {},
  dataIds: [],
  onceFetched: false,
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const campusAmbassador = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_CAMPUS_AMBASSADORS}_SUCCESS`: {
      const {
        data,
        relation,
        dataIds,

        paginationData,
      } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        onceFetched: true,
      };
    }

    case `${CREATE_CAMPUS_AMBASSADOR}_SUCCESS`: {
      const { data, inclued } = payload;
      const newData = { ...store.data };
      newData[data.id] = { ...data };
      const newRelation = { ...store.relation };
      if (Array.isArray(inclued)) {
        inclued.forEach((item) => {
          newRelation[`${item.id}_${item.type}`] = {
            ...item,
            [Symbol.iterator]: createRelationIterator(),
          };
        });
      }
      const newIds = [...store.dataIds, data.id];
      return {
        ...store,
        data: newData,
        relation: newRelation,
        dataIds: newIds,
      };
    }
    default:
      return store;
  }
};

export default campusAmbassador;

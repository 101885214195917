import { combineReducers } from "redux";

import appDataReducer from "./appData";
import authReducer from "./auth";
import alertReducer from "./alert";
import databaseReducer from "./database";
import generalSearchReducer from "./generalSearch";
import searchByReducer from "./searchBy";
import entriesReducer from "./entries";
import unregisteredParticipantsReducer from "./unregisteredParticipants";
import failedRegistrationsReducer from "./failedRegistrations";
import dailyReportsReducer from "./dailyReports";
import enquiryReducer from "./enquiry";
import chartsReducer from "./charts";
import updateUserReducer from "./updateUser";
import mailReducer from "./mail";
import visitorResponseReducer from "./visitorResponse";
import callbackRequestReducer from "./callbackRequest";
import AnnouncementReducer from "./announcement";
import RefundRegistrationReducer from "./refundRegistration";

import UsedVouchersReducer from "./vouchers/usedVouchers";
import DueVouchersReducer from "./vouchers/dueVouchers";
import LeftVouchersReducer from "./vouchers/leftVouchers";

import StudyMaterialReducer from "./studyMaterial";
import CampusAmbassadorReducer from "./campusAmbassador";

import PriorityEnquiryReducer from "./priorityEnquiry";
import LeaderBoardReducer from "./leaderBoard";
import CreateCertificateReducer from "./Certificate/CreateCertificate";
import ViewCertificateReducer from "./Certificate/ViewCertificate";
import ViewCertificateByRankReducer from "./Certificate/ViewCertificateByRank";
import SendCertificateReducer from "./Certificate/SendCertificate";
import DiscountViewReducer from "./Discounts/view";

import userReducer from "./User/user";
import isbUserReducer from "./User/isbUser";

import interactWithUsReducer from "./interactWithUs";

import { registeredSeminar, unRegisteredSeminar } from "./Seminar";
import mailReportReducer from "./mailReport";
import unsubscribeMailReducer from "./unsubscribeMail";

const rootReducer = combineReducers({
  appData: appDataReducer,
  auth: authReducer,
  alert: alertReducer,
  database: databaseReducer,
  entries: entriesReducer,
  generalSearch: generalSearchReducer,
  searchBy: searchByReducer,
  charts: chartsReducer,
  failedRegistrations: failedRegistrationsReducer,
  unregisteredParticipants: unregisteredParticipantsReducer,
  enquiry: enquiryReducer,
  updateUser: updateUserReducer,
  mail: mailReducer,
  mailReport: mailReportReducer,
  user: userReducer,
  isbUser: isbUserReducer,
  callbackRequest: callbackRequestReducer,
  announcement: AnnouncementReducer,
  visitorResponse: visitorResponseReducer,
  refundRegistration: RefundRegistrationReducer,
  usedVouchers: UsedVouchersReducer,
  leftVouchers: LeftVouchersReducer,
  dueVouchers: DueVouchersReducer,
  studyMaterial: StudyMaterialReducer,
  campusAmbassador: CampusAmbassadorReducer,
  priorityEnquiry: PriorityEnquiryReducer,
  leaderBoard: LeaderBoardReducer,
  createCertificate: CreateCertificateReducer,
  viewCertificate: ViewCertificateReducer,
  viewCertificateByRank: ViewCertificateByRankReducer,
  sendCertificate: SendCertificateReducer,
  discountsView: DiscountViewReducer,
  interactWithUs: interactWithUsReducer,
  registeredSeminar,
  unRegisteredSeminar,
  dailyReports: dailyReportsReducer,
  unsubscribeMail: unsubscribeMailReducer,
});

export default rootReducer;

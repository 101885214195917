import React, { useMemo } from "react";

import DrawerItem from "../DrawerItem";

import DrawerTab from "../DrawerTab";
import Routes from "../../../Constants/Routes";

const { refundsManagement } = Routes.authorized;

const tabLinks = Object.keys(refundsManagement.routes).map(
  (key) => refundsManagement.routes[key].path
);

const RefundsTab = ({ location }) => {
  const shouldRefundBeCollapsed = useMemo(() => {
    return tabLinks.includes(location.pathname);
  }, [location]);

  const items = useMemo(() => {
    return Object.keys(refundsManagement.routes).map((key) => {
      const {
        path,
        drawerTitle,
        drawerIcon,
        accessType,
      } = refundsManagement.routes[key];
      return (
        <DrawerItem
          key={path}
          currentPath={location.pathname}
          path={path}
          title={drawerTitle}
          icon={drawerIcon}
          accessType={accessType}
        />
      );
    });
  }, [location]);

  return (
    <DrawerTab
      active={shouldRefundBeCollapsed}
      currentPath={location.pathname}
      title="Refund Registrations"
      icon="fas fa-hand-holding-usd"
      accessType={refundsManagement.accessType}
    >
      {items}
    </DrawerTab>
  );
};

export default RefundsTab;

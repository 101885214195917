export const searchRoutes = {
  searchManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      search: {
        path: "/search",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "General Search",
        drawerIcon: "fas fa-search",
      },
      searchBy: {
        path: "/search_by",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "Search By",
        drawerIcon: "fas fa-filter",
      },
    },
  },
};

import { GET_CHARTS_DATA } from "../Actions";

const initialStore = {
  chartsData: {}
};

const charts = (store = initialStore, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_CHARTS_DATA}_SUCCESS`: {
      const totalData = payload;

      const result = {};

      Object.keys(totalData).forEach((categories) => {
        const currentCategory = totalData[categories];

        Object.keys(currentCategory).forEach((subCategory) => {
          const currentSubCategory = currentCategory[subCategory];

          if (typeof currentSubCategory === "object") {
            result[subCategory] = {
              ...(result[subCategory] || {})
            };

            const groupCategories = result[subCategory];

            Object.keys(currentSubCategory).forEach((groupKey) => {
              const prevValue = groupCategories[groupKey];
              const currentValue = currentSubCategory[groupKey];

              groupCategories[groupKey] = prevValue
                ? prevValue + currentValue
                : currentValue;
            });
          }

          if (typeof currentSubCategory === "number") {
            result[subCategory] = result[subCategory]
              ? currentSubCategory + result[subCategory]
              : currentSubCategory;
          }
        });
      });

      return {
        ...store,
        chartsData: {
          ...payload,
          total: result
        }
      };
    }

    default:
      return store;
  }
};

export default charts;

import React, { lazy } from "react";
import Routes from "../../Constants/Routes";
import AdvancedRoute from "../../Components/AdvancedRoute";

const announcementManagement = Routes.authorized.announcementManagement.routes;

const CreateAnnouncementPage = lazy(() =>
  import("../../Pages/Announcement/CreateAnnouncement")
);
const EditAnnouncementPage = lazy(() =>
  import("../../Pages/Announcement/EditAnnouncement")
);
const ViewAnnouncementPage = lazy(() =>
  import("../../Pages/Announcement/ViewAnnouncement")
);

export const AnnouncementRoutes = () => {
  return (
    <>
      <AdvancedRoute
        exact
        path={announcementManagement.createAnnouncement.path}
        accessType={announcementManagement.createAnnouncement.accessType}
        component={CreateAnnouncementPage}
      />

      <AdvancedRoute
        exact
        path={announcementManagement.editAnnouncement.path}
        accessType={announcementManagement.editAnnouncement.accessType}
        component={EditAnnouncementPage}
      />

      <AdvancedRoute
        exact
        path={announcementManagement.viewAnnouncement.path}
        accessType={announcementManagement.viewAnnouncement.accessType}
        component={ViewAnnouncementPage}
      />
    </>
  );
};

import React, { useMemo } from "react";

import DrawerItem from "../DrawerItem";
import DrawerTab from "../DrawerTab";

import Routes from "../../../Constants/Routes";

const { ambassadorManagement } = Routes.authorized;

const tabLinks = Object.keys(ambassadorManagement.routes).map(
  (key) => ambassadorManagement.routes[key].path
);

const AmbassadorTab = ({ location }) => {
  const shouldCampusBeCollapsed = useMemo(() => {
    return tabLinks.includes(location.pathname);
  }, [location]);

  const items = useMemo(() => {
    return Object.keys(ambassadorManagement.routes).map((key) => {
      const {
        path,
        drawerTitle,
        drawerIcon,
        accessType,
      } = ambassadorManagement.routes[key];
      return (
        <DrawerItem
          key={path}
          currentPath={location.pathname}
          path={path}
          title={drawerTitle}
          icon={drawerIcon}
          accessType={accessType}
        />
      );
    });
  }, [location]);

  return (
    <DrawerTab
      active={shouldCampusBeCollapsed}
      currentPath={location.pathname}
      title="Campus Ambassador"
      icon="fas fa-graduation-cap"
      accessType={ambassadorManagement.accessType}
    >
      {items}
    </DrawerTab>
  );
};

export default AmbassadorTab;

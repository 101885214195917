export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_CURRENT_ISB_VERSION = "UPDATE_CURRENT_ISB_VERSION";

export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const ACTIVATE_DRAWER = "ACTIVATE_DRAWER";
export const DE_ACTIVATE_DRAWER = "DE_ACTIVATE_DRAWER";

export const CLEAR_STORE = "CLEAR_STORE";
export const SEND_RESET_PASSWORD_OTP = "SEND_RESET_PASSWORD_OTP";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";

export const ACTIVATE_ALERT = "ACTIVATE_ALERT";
export const DE_ACTIVATE_ALERT = "DE_ACTIVATE_ALERT";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_SEARCH_BY_TYPE_DATA = "GET_SEARCH_BY_TYPE_DATA";
export const UPDATE_SEARCH_BY_FIELDS_DATA = "UPDATE_SEARCH_BY_FIELDS_DATA";

export const GET_SPECIFIC_DATABASE_RECORDS = "GET_SPECIFIC_DATABASE_RECORDS";
export const GET_DATABASE_RECORDS = "GET_DATABASE_RECORDS";
export const GET_DATABASE_RECORDS_COUNT = "GET_DATABASE_RECORDS_COUNT";
export const UPDATE_DATABASE_SEARCH_CATEGORY =
  "UPDATE_DATABASE_SEARCH_CATEGORY";
export const UPDATE_DATABASE_SEARCH_VALUE = "UPDATE_DATABASE_SEARCH_VALUE";
export const SET_PREV_DATABASE_DATA = "SET_PREV_DATABASE_DATA";

export const SET_PREV_TABLE_DATA = "SET_PREV_TABLE_DATA";
export const DELETE_TABLE_RECORD = "DELETE_TABLE_RECORD";

export const GET_UN_ANSWERED_QUERIES = "GET_UN_ANSWERED_QUERIES";
export const GET_ANSWERED_QUERIES = "GET_ANSWERED_QUERIES";
export const GET_ALL_QUERIES = "GET_ALL_QUERIES";
export const GET_QUERIES_BY_TICKET_ID = "GET_QUERIES_BY_TICKET_ID";
export const DOWNLOAD_ENQUIRIES_CSV = "DOWNLOAD_ENQUIRIES_CSV";

export const ANSWER_QUERY = "ANSWER_QUERY";
export const CHANGE_ENQUIRY_CATEGORY = "CHANGE_ENQUIRY_CATEGORY";

export const GET_UNREGISTERED_USER = "GET_UNREGISTERED_USER";
export const GET_UNREGISTERED_PARTICIPANTS = "GET_UNREGISTERED_PARTICIPANTS";
export const GET_UNREGISTERED_PARTICIPANTS_COUNT =
  "GET_UNREGISTERED_PARTICIPANTS_COUNT";
export const GET_TODAYS_ISB_REGISTRATION_COUNT =
  "GET_TODAYS_ISB_REGISTRATION_COUNT";
export const DOWNLOAD_UNREGISTERED_CSV = "DOWNLOAD_UNREGISTERED_CSV";

export const GET_FAILED_REGISTRATIONS = "GET_FAILED_REGISTRATIONS";

export const GET_CALLBACK_REQUESTS = "GET_CALLBACK_REQUESTS";
export const DOWNLOAD_CALLBACK_REQUESTS_CSV = "DOWNLOAD_CALLBACK_REQUESTS_CSV";

export const GET_REFUND_REGISTRATIONS = "GET_REFUND_REGISTRATIONS";
export const CREATE_REFUND_REGISTRATION = "CREATE_REFUND_REGISTRATION";

export const SEND_WELCOME_NOTIFICATION = "SEND_WELCOME_NOTIFICATION";
export const SEND_MAIL_NOTIFICATION = "SEND_MAIL_NOTIFICATION";

export const CAPTURE_AUTHORIZED_PAYMENT = "CAPTURE_AUTHORIZED_PAYMENT";

export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const GET_USER_DETAILS_TO_UPDATE = "GET_USER_DETAILS_TO_UPDATE";

export const CREATE_ISB_ANNOUNCEMENT = "CREATE_ISB_ANNOUNCEMENT";
export const GET_ISB_ANNOUNCEMENT = "GET_ISB_ANNOUNCEMENT";
export const CHANGE_ANNOUNCEMENT_CATEGORY = "CHANGE_ANNOUNCEMENT_CATEGORY";
export const UPDATE_ISB_ANNOUNCEMENT = "UPDATE_ISB_ANNOUNCEMENT";
export const DELETE_ISB_ANNOUNCEMENT = "DELETE_ISB_ANNOUNCEMENT";

export const CREATE_MAIL = "CREATE_MAIL";
export const UPDATE_MAIL = "UPDATE_MAIL";
export const DELETE_MAIL = "DELETE_MAIL";
export const GET_EMAILS = "GET_EMAILS";
export const CHANGE_EMAIL_CATEGORY = "CHANGE_EMAIL_CATEGORY";
export const CHANGE_EDIT_EMAIL_CATEGORY = "CHANGE_EDIT_EMAIL_CATEGORY";
export const SEND_EMAIL = "SEND_EMAIL";
export const GET_REGISTRATIONS_EMAIL = "GET_REGISTRATIONS_EMAIL";
export const UPDATE_REGISTRATIONS_EMAIL = "UPDATE_REGISTRATIONS_EMAIL";
export const DELETE_REGISTRATIONS_EMAIL = "DELETE_REGISTRATIONS_EMAIL";
export const GET_EMAIL_REPORTS = "GET_EMAIL_REPORTS";
export const GET_DAILY_EMAIL_REPORT_COUNT = "GET_DAILY_EMAIL_REPORT_COUNT";
export const UPDATE_FETCH_EMAIL_REPORTS_FLAG =
  "UPDATE_FETCH_EMAIL_REPORTS_FLAG";
export const SEARCH_EMAIL_REPORTS = "SEARCH_EMAIL_REPORTS";
export const DELETE_EMAIL_REPORTS = "DELETE_EMAIL_REPORTS";
export const CREATE_UNSUBSCRIBE_MAIL = "CREATE_UNSUBSCRIBE_MAIL";
export const GET_UNSUBSCRIBE_MAIL = "GET_UNSUBSCRIBE_MAIL";
export const SEARCH_UNSUBSCRIBE_MAIL = "SEARCH_UNSUBSCRIBE_MAIL";
export const UPDATE_EMAIL_SEARCH_QUERY = "UPDATE_EMAIL_SEARCH_QUERY";

export const GET_VISITOR_RESPONSE = "GET_VISITOR_RESPONSE";

export const GET_DUE_VOUCHERS = "GET_DUE_VOUCHERS";
export const GET_LEFT_VOUCHERS = "GET_LEFT_VOUCHERS";
export const GET_USED_VOUCHERS = "GET_USED_VOUCHERS";
export const CREATE_VOUCHER = "CREATE_VOUCHER";

export const GET_SUBJECTS = "GET_SUBJECTS";
export const CREATE_SUBJECT = "CREATE_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const CHANGE_SUBJECT_TO_EDIT = "CHANGE_SUBJECT_TO_EDIT";

export const GET_LECTURES = "GET_LECTURES";
export const CREATE_LECTURE = "CREATE_LECTURE";
export const UPDATE_LECTURE = "UPDATE_LECTURE";
export const DELETE_LECTURE = "DELETE_LECTURE";
export const CHANGE_LECTURE_TO_EDIT = "CHANGE_LECTURE_TO_EDIT";

export const CREATE_LECTURE_MATERIAL = "CREATE_LECTURE_MATERIAL";
export const UPDATE_LECTURE_MATERIAL = "UPDATE_LECTURE_MATERIAL";
export const DELETE_LECTURE_MATERIAL = "DELETE_LECTURE_MATERIAL";
export const CHANGE_LECTURE_MATERIAL_TO_EDIT =
  "CHANGE_LECTURE_MATERIAL_TO_EDIT";

export const CLEAR_LECTURES = "CLEAR_LECTURES";

export const CREATE_CAMPUS_AMBASSADOR = "CREATE_CAMPUS_AMBASSADOR";
export const GET_CAMPUS_AMBASSADORS = "GET_CAMPUS_AMBASSADORS";

export const GET_ANSWERED_PRIORITY_ENQUIRIES =
  "GET_ANSWERED_PRIORITY_ENQUIRIES";
export const GET_UN_ANSWERED_PRIORITY_ENQUIRIES =
  "GET_UN_ANSWERED_PRIORITY_ENQUIRIES";
export const UPDATE_PRIORITY_ENQUIRY_STATUS = "UPDATE_PRIORITY_ENQUIRY_STATUS";
export const CHANGE_PRIORITY_TYPE = "CHANGE_PRIORITY_TYPE";

export const GET_LEADERBOARD_SCORES = "GET_LEADERBOARD_SCORES";
export const CREATE_LEADERBOARD_SCORE = "CREATE_LEADERBOARD_SCORE";
export const UPDATE_LEADERBOARD_SCORE = "UPDATE_LEADERBOARD_SCORE";
export const UPDATE_SELECTED_SCORE_ID = "UPDATE_SELECTED_SCORE_ID";
export const CHANGE_LEADERBOARD_ACTION = "CHANGE_LEADERBOARD_ACTION";
export const SEARCH_PARTICIPANT_SCORE = "SEARCH_PARTICIPANT_SCORE";
export const SET_PREV_LEADERBOARD_DATA = "SET_PREV_LEADERBOARD_DATA";
export const GET_SORTED_LEADERBOARD_SCORE = "GET_SORTED_LEADERBOARD_SCORE";

export const GET_TOP_RANK_HOLDERS = "GET_TOP_RANK_HOLDERS";
export const GET_TOP_STUDENTS_OF_SUBJECT = "GET_TOP_STUDENTS_OF_SUBJECT";
export const UPDATE_CERTIFICATE_META_DATA = "UPDATE_CERTIFICATE_META_DATA";

export const UPLOAD_CERTIFICATE_URL = "UPLOAD_CERTIFICATE_URL";

export const GET_CERTIFICATE_RECORDS = "GET_CERTIFICATE_RECORDS";
export const CHANGE_SELECTED_SUBJECT = "CHANGE_SELECTED_SUBJECT";

export const GET_CERTIFICATES_BY_RANK = "GET_CERTIFICATES_BY_RANK";
export const UPDATE_RANK_CERTIFICATE_META_DATA =
  "UPDATE_RANK_CERTIFICATE_META_DATA";

export const GET_STUDENT_CERTIFICATE_LINKS = "GET_STUDENT_CERTIFICATE_LINKS";
export const SEND_CERTIFICATE_TO_STUDENT = "SEND_CERTIFICATE_TO_STUDENT";

export const CREATE_USER = "CREATE_USER";
export const CREATE_ISB_USER = "CREATE_ISB_USER";
export const GET_ALL_ISB_DATA = "GET_ALL_ISB_DATA";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_ISB_USER_DATA = "UPDATE_ISB_USER_DATA";
export const GET_USER_RECORDS = "GET_USER_RECORDS";
export const GET_ISB_USER_RECORD = "GET_ISB_USER_RECORD";
export const DELETE_ISB_USER_RECORD = "DELETE_ISB_USER_RECORD";

export const GET_INTERACT_WITH_US_DATA = "GET_INTERACT_WITH_US_DATA";
export const CHANGE_INTERACT_WITH_US_CATEGORY =
  "CHANGE_INTERACT_WITH_US_CATEGORY";
export const SEARCH_INTERACT_WITH_US = "SEARCH_INTERACT_WITH_US";
export const SET_PREV_INTERACT_WITH_US_DATA = "SET_PREV_INTERACT_WITH_US_DATA";
export const CHANGE_INTERACT_WITH_US_SEARCH_VALUE =
  "CHANGE_INTERACT_WITH_US_SEARCH_VALUE";

export const GET_ISB_REGISTRATION_BY_DATE = "GET_ISB_REGISTRATION_BY_DATE";

export * from "./discounts";
export * from "./seminar";

export const participantRoutes = {
  participantManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      unregisteredParticipants: {
        path: "/unregistered_participants",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Unregistered Participants",
        drawerIcon: "fas fa-exclamation-circle",
      },

      database: {
        path: "/database",
        accessType: ["super_admin"],
        drawerTitle: " Registered Participant",
        drawerIcon: "fas fa-database",
      },

      failedRegistrations: {
        path: "/failed_registrations",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Failed Registrations",
        drawerIcon: "fas fa-exclamation-circle",
      },

      dailyReports: {
        path: "/daily_reports",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Daily Reports",
        drawerIcon: "fas fa-file-signature",
      },

      charts: {
        path: "/charts",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Charts",
        drawerIcon: "fas fa-chart-pie",
      },
    },
  },
};

import React, { useEffect, useLayoutEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import OnlyWhenAuthenticated from "../Components/Auth/OnlyWhenAuthenticated";
import Alert from "../Components/Alert";

import AuthorizedRoutes from "./AuthorizedRoutes";
import UnAuthorizedRoutes from "./UnAuthorizedRoutes";
import { setGlobalUserDetils } from "../GlobalData";

import {
  TOKEN_LOCAL_NAME,
  USER_LOCAL_DATA,
  CURRENT_ISB_DATA,
} from "../Constants";

import Routes from "../Constants/Routes";
import {
  isUserLoggedIn,
  getAccessType,
  getUserData,
  getCurrentIsbData,
  getToken,
} from "../Selectors/auth";

const authorizedPathNames = {};
const unAuthorizedPathNames = {};

Object.keys(Routes.authorized).forEach((key) => {
  const tab = Routes.authorized[key];
  Object.keys(tab.routes).forEach((page) => {
    const item = tab.routes[page];
    authorizedPathNames[item.path] = true;
  });
});

Object.keys(Routes.unAuthorized).map((key) => {
  const page = Routes.unAuthorized[key];
  unAuthorizedPathNames[page.path] = true;
});

const MainRoute = () => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const hasAccessType = useSelector(getAccessType);
  const userData = useSelector(getUserData);
  const currentIsbData = useSelector(getCurrentIsbData);
  const currentToken = useSelector(getToken);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn && hasAccessType) {
      if (unAuthorizedPathNames[pathname]) {
        history.replace(Routes.authorized.searchManagement.routes.search.path);
      }
    } else {
      if (authorizedPathNames[pathname]) {
        history.replace("/");
      }
    }
  }, [history, isLoggedIn, pathname, hasAccessType]);

  useLayoutEffect(() => {
    window.sessionStorage.setItem(TOKEN_LOCAL_NAME, currentToken);
    window.sessionStorage.setItem(USER_LOCAL_DATA, JSON.stringify(userData));
    window.sessionStorage.setItem(
      CURRENT_ISB_DATA,
      JSON.stringify(currentIsbData)
    );
    setGlobalUserDetils({ ...userData });
  }, [userData, currentIsbData, currentToken]);

  return (
    <>
      <UnAuthorizedRoutes />
      <OnlyWhenAuthenticated>
        <AuthorizedRoutes />
      </OnlyWhenAuthenticated>
      <Alert />
    </>
  );
};

export default MainRoute;

export const notificationRoutes = {
  notificationManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      sendWelcomeNotification: {
        path: "/send_welcome_notification",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "Welcome Notifications",
        drawerIcon: "fas fa-handshake",
      },
      sendMailNotification: {
        path: "/send_mail_notification",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Mailer Notifications",
        drawerIcon: "fas fa-envelope-open-text",
      },

      sendsendSmsNotificationMail: {
        path: "/send_sms_notification",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Sms Notifications",
        drawerIcon: "fas fa-sms",
      },
    },
  },
};

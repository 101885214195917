export const announcementRoutes = {
  announcementManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      createAnnouncement: {
        path: "/create_announcement",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Create Announcement",
        drawerIcon: "fas fa-pencil-alt",
      },
      editAnnouncement: {
        path: "/edit_announcement",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Edit Announcement",
        drawerIcon: "fas fa-wrench",
      },

      viewAnnouncement: {
        path: "/view_announcement",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "View Announcement",
        drawerIcon: "fas fa-eye",
      },
    },
  },
};

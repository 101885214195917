export const seminarRoutes = {
  seminarManagement: {
    accessType: ["admin", "super_admin", "sales_executive"],
    routes: {
      viewRegistered: {
        path: "/view_registered_seminar_participants",
        accessType: ["admin", "super_admin"],
        drawerTitle: "View Registered",
        drawerIcon: "fas fa-eye",
      },
      viewUnRegistered: {
        path: "/view_unregistered_seminar_participants",
        accessType: ["admin", "super_admin", "sales_executive"],
        drawerTitle: "View Un-Registered",
        drawerIcon: "fas fa-eye",
      },
    },
  },
};

const setSuccess = (dispatch, actionType, response, extraParams) => {
  dispatch({
    ...extraParams,
    type: `${actionType}_SUCCESS`,
    payload: response,
  });
  //  dispatch({type: `${actionType}_PROGRESS_COMPLETED`});
};

const setFailed = (dispatch, actionType, error) => {
  dispatch({ type: `${actionType}_FAILED`, error });
};

const setStart = (dispatch, updateAction = "") => (
  actionType,
  options = {}
) => {
  const { successParams = {}, data = null } = options;

  if (!data) {
    dispatch({ type: `${actionType}` });
  } else {
    dispatch({ type: `${actionType}`, payload: data });
  }

  if (typeof updateAction === "function") {
    // dispatch({type: `${actionType}_PROGRESS_STARTED`});
    updateAction(actionType, successParams);
  }
};

const promiseCreator = (store) => (next) => (actionFunction) => {
  if (typeof actionFunction === "function") {
    const wrappedFunc = (dispatch) => {
      let action = "";
      let successParams = {};

      const promise = new Promise(async (resolve, reject) => {
        const result = actionFunction(
          dispatch,
          setStart(dispatch, (type, params = {}) => {
            action = type;
            successParams = params;
          })
        );

        if (result && typeof result.then === "function") {
          result
            .then((res = true) => {
              if (res.urlBlocked) {
                resolve(res);
                return;
              }
              setSuccess(dispatch, action, res, successParams);
              resolve(res);
            })
            .catch((err = "") => {
              setFailed(dispatch, action, err.message || err);
              reject(err.message || err);
            });
        } else {
          resolve(true);
        }
      });
      return promise;
    };

    return next(wrappedFunc);
  }
  return next(actionFunction);
};

export default promiseCreator;

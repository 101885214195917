export const voucherRoutes = {
  voucherManagement: {
    accessType: ["admin", "super_admin"],
    routes: {
      dueVouchers: {
        path: "/due_vouchers",
        accessType: ["admin", "super_admin"],
        drawerTitle: "Due",
        drawerIcon: "fas fa-calendar-alt",
      },

      leftVouchers: {
        path: "/left_vouchers",
        accessType: ["super_admin"],
        drawerTitle: "Left",
        drawerIcon: "fas fa-balance-scale-right",
      },

      usedVouchers: {
        path: "/used_vouchers",
        accessType: ["super_admin"],
        drawerTitle: "Used",
        drawerIcon: "fas fa-people-arrows",
      },

      createVoucher: {
        path: "/create_voucher",
        accessType: ["super_admin"],
        drawerTitle: "Create",
        drawerIcon: "fas fa-pencil-alt",
      },
    },
  },
};

import { GET_USER_DETAILS } from "../Actions";

const initialState = {
  data: {},
  relation: {},
  dataIds: [],
};

const generalSearch = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_USER_DETAILS}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
      };
    }

    case `CLEAR_GENERAL_SEARCH_DATA`: {
      return {
        ...store,
        data: {},
        relation: {},
        dataIds: [],
      };
    }

    default:
      return store;
  }
};

export default generalSearch;

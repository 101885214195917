import { TOGGLE_DRAWER, ACTIVATE_DRAWER, DE_ACTIVATE_DRAWER } from "../Actions";

const initialState = {
  drawerActive: window.innerWidth >= 1024 ? true : false,
};
const appData = (store = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER: {
      return {
        ...store,
        drawerActive: !store.drawerActive,
      };
    }

    case ACTIVATE_DRAWER: {
      return {
        ...store,
        drawerActive: true,
      };
    }

    case DE_ACTIVATE_DRAWER: {
      return {
        ...store,
        drawerActive: false,
      };
    }

    default: {
      return store;
    }
  }
};

export default appData;

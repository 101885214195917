import { GET_TODAYS_ISB_REGISTRATION_COUNT } from "../Actions";

const initialState = {
  todaysIsbRegistrations: null
};

const entries = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_TODAYS_ISB_REGISTRATION_COUNT}_SUCCESS`: {
      const { count } = payload;

      return {
        ...store,
        todaysIsbRegistrations: count
      };
    }
    default:
      return store;
  }
};

export default entries;

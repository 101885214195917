export const createInitialStore = (extraData = {}) => {
  return {
    currentCategoryId: "",
    dataFetched: false,
    data: {},
    dataIds: [],
    relation: {},

    paginationData: {
      hasNextPage: true,
      hasPrevPage: null,
      prevPageNumber: -1,
      currentPage: 1,
      nextPageNumber: -1,
      currentPageSize: 0,
      totalPages: 1,
    },
    ...extraData,
  };
};

export const createInitialPageStore = (payload, store) => {
  const { data, dataIds, relation, paginationData } = payload;
  return {
    ...store,
    data,
    paginationData,
    dataIds,
    relation,
    dataFetched: true,
  };
};

export const updateDataOnDelete = (store, id) => {
  let { data, dataIds } = store;

  const newData = { ...data };
  const newDataIds = [...dataIds];

  delete newData[id];

  const indexOfId = newDataIds.indexOf(id);
  newDataIds.splice(indexOfId, 1);

  return {
    ...store,
    data: { ...newData },
    dataIds: [...newDataIds],
  };
};

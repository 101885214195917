import React, { useCallback } from "react";

import Select from "../Input/Select";
import useIsbVersion from "../../hooks/useIsbVersion";
import styles from "./Navbar.module.css";
const Version = () => {
  const { changeIsbVersion, versionOptions, currentIsbData } = useIsbVersion();

  const onVersionChange = useCallback((e) => {
    changeIsbVersion(e.target.value);
  }, []);

  return (
    <div className={styles.version_cont}>
      <Select
        value={currentIsbData.id}
        className={styles.version_select}
        containerClass={styles.version_select_box}
        options={versionOptions}
        onChange={onVersionChange}
        defaultSelectText="Select Version"
      />
    </div>
  );
};
export default Version;

import {
  DELETE_TABLE_RECORD,
  GET_INTERACT_WITH_US_DATA,
  CHANGE_INTERACT_WITH_US_CATEGORY,
  SEARCH_INTERACT_WITH_US,
  SET_PREV_INTERACT_WITH_US_DATA,
  CHANGE_INTERACT_WITH_US_SEARCH_VALUE,
} from "../Actions";
import { ALL_QUERIES } from "../Constants";

const initialState = {
  data: {},
  relation: {},
  dataIds: [],
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
  onceFetched: false,
  currentCategorySelected: ALL_QUERIES,
  currentSearchValue: "",
  prevData: {},
};

const interactWithUs = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${SEARCH_INTERACT_WITH_US}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;
      let prevData = {};
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
      } else {
        prevData = { ...store.prevData };
        prevData.prevData = {};
      }

      Reflect.deleteProperty(prevData, "currentCategorySelected");
      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        prevData,
        onceFetched: true,
      };
    }

    case `${GET_INTERACT_WITH_US_DATA}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;
      return {
        ...store,
        data,
        relation,
        dataIds,
        paginationData,
        onceFetched: true,
      };
    }

    case SET_PREV_INTERACT_WITH_US_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
      };
    }

    case CHANGE_INTERACT_WITH_US_CATEGORY: {
      const { category } = payload;
      return {
        ...store,
        currentCategorySelected: category,
      };
    }

    case CHANGE_INTERACT_WITH_US_SEARCH_VALUE: {
      const { searchValue } = payload;
      return {
        ...store,
        currentSearchValue: searchValue,
      };
    }

    case DELETE_TABLE_RECORD: {
      const { id } = payload;
      const newData = { ...store.data };
      delete newData[id];
      const newIds = [...store.dataIds.filter((itemId) => itemId !== id)];

      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    case `CLEAR_INTERACT_WITH_US_DATA`: {
      return {
        ...store,
        data: {},
        relation: {},
        dataIds: [],
        onceFetched: false,
        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1,
        },
      };
    }
    default:
      return store;
  }
};

export default interactWithUs;

import React, { useMemo } from "react";

import DrawerItem from "../DrawerItem";
import DrawerTab from "../DrawerTab";

import Routes from "../../../Constants/Routes";

const { searchManagement } = Routes.authorized;

const tabLinks = Object.keys(searchManagement.routes).map(
  (key) => searchManagement.routes[key].path
);

const SearchTab = ({ location }) => {
  const shouldSearchBeCollapsed = useMemo(() => {
    return tabLinks.includes(location.pathname);
  }, [location]);

  const items = useMemo(() => {
    return Object.keys(searchManagement.routes).map((key) => {
      const {
        path,
        drawerTitle,
        drawerIcon,
        accessType,
      } = searchManagement.routes[key];
      return (
        <DrawerItem
          key={path}
          currentPath={location.pathname}
          path={path}
          title={drawerTitle}
          icon={drawerIcon}
          accessType={accessType}
        />
      );
    });
  }, [location]);

  return (
    <DrawerTab
      active={shouldSearchBeCollapsed}
      currentPath={location.pathname}
      title="Search"
      icon="fas fa-search"
      accessType={searchManagement.accessType}
    >
      {items}
    </DrawerTab>
  );
};

export default SearchTab;

import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import OnlyWhenAuthorized from "../Auth/OnlyWhenAuthorized";
import PropTypes from "prop-types";

import styles from "./Drawer.module.css";

const Item = ({ icon, active, title }) => {
  return (
    <>
      <Icon
        className={`${styles.drawer_icon} ${active ? styles.active : ""}`}
        name={icon}
      />
      <span className={`${styles.drawer_text} ${active ? styles.active : ""}`}>
        {title}
      </span>
    </>
  );
};

const DrawerItem = memo(
  ({
    path = "",
    currentPath = "",
    title,
    icon,
    visible = true,
    isLink = true,
    accessType,
    onClick = null,
  }) => {
    const active = useMemo(() => {
      return path && currentPath && path === currentPath;
    }, [path, currentPath]);

    if (visible) {
      if (isLink) {
        return (
          <OnlyWhenAuthorized requiredAccess={accessType}>
            <Link to={path} className={`${styles.drawer_item} `}>
              <Item title={title} icon={icon} active={active} />
            </Link>
          </OnlyWhenAuthorized>
        );
      } else {
        return (
          <div
            role={onClick ? "button" : "group"}
            onClick={onClick}
            className={`${styles.drawer_item} `}
          >
            <Item title={title} icon={icon} active={active} />
          </div>
        );
      }
    }
  }
);

Item.propTypes = {
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

DrawerItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string,
  currentPath: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  isLink: PropTypes.bool,
  accessType: PropTypes.arrayOf(PropTypes.oneOf(["admin", "super_admin"])),
  onClick: PropTypes.func,
};

export default DrawerItem;

import React, { useMemo } from "react";

import DrawerItem from "../DrawerItem";
import DrawerTab from "../DrawerTab";

import Routes from "../../../Constants/Routes";

const { discountManagement } = Routes.authorized;

const tabLinks = Object.keys(discountManagement.routes).map(
  (key) => discountManagement.routes[key].path
);

const DiscountsTab = ({ location }) => {
  const shouldDiscountsBeCollapsed = useMemo(() => {
    return tabLinks.includes(location.pathname);
  }, [location]);

  const items = useMemo(() => {
    return Object.keys(discountManagement.routes).map((key) => {
      const {
        path,
        drawerTitle,
        drawerIcon,
        accessType,
      } = discountManagement.routes[key];
      return (
        <DrawerItem
          key={path}
          currentPath={location.pathname}
          path={path}
          title={drawerTitle}
          icon={drawerIcon}
          accessType={accessType}
        />
      );
    });
  }, [location]);

  return (
    <DrawerTab
      active={shouldDiscountsBeCollapsed}
      currentPath={location.pathname}
      title="Discount"
      icon="fas fa-percent"
      accessType={discountManagement.accessType}
    >
      {items}
    </DrawerTab>
  );
};

export default DiscountsTab;

let cache = {};

const storeInCache = (container, key, value) => {
  cache = {
    ...cache,
    [container]: {
      ...(cache[container] || {}),
      [key]: value,
    },
  };
};

const isInCache = (container, key) => {
  return cache[container] && cache[container][key];
};
export const generateRandomId = () => {
  let id = "";
  for (let i = 0; i < 6; i++) {
    id += Math.ceil(Math.random() * 10);
  }
  return id;
};

export const getDateInLocal = (date) => {
  const d = new Date(String(date));
  return d.toLocaleDateString();
};

const createJoinedString = (value, joinWith = " ") => {
  if (!value) {
    return " ";
  }
  let res = "";
  for (let i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) < 97) {
      res += joinWith + value.charAt(i);
    } else {
      res += value.charAt(i);
    }
  }

  return res;
};

const replaceCharacter = (value = "", replace = " ", replaceWith = " ") => {
  if (!value) {
    return "";
  }
  const stringArr = value.split(replace);
  return stringArr.join(replaceWith);
};

export const replaceUnderScoreWithSpace = (value) => {
  const cachedValue = isInCache("replaceUnderscore", value);
  if (cachedValue) {
    return cachedValue;
  }
  const result = replaceCharacter(value, "_", " ");
  storeInCache("replaceUnderscore", value, result);
  return result;
};

const removeGroupBy = (value) => {
  if (!value) {
    return "";
  }
  return value.replace("group by", "");
};

export const removeGroupByFromChartTitle = (value) => {
  const cachedValue = isInCache("removeGroupBy", value);
  if (cachedValue) {
    return cachedValue;
  }
  const result = removeGroupBy(value);
  storeInCache("removeGroupBy", value);
  return result;
};

export const getUnderScoreString = (value) => {
  const cachedValue = isInCache("underscore", value);

  if (cachedValue) {
    return cachedValue;
  }
  const result = createJoinedString(value, "_");
  storeInCache("underscore", value, result);
  return result;
};

export const removePrevUnderScoreString = (value = "") => {
  const cachedValue = isInCache("prevUnderscore", value);

  if (cachedValue) {
    return cachedValue;
  }

  if (typeof value !== "string") {
    return value;
  }

  const lastIndex = value.lastIndexOf("_");
  const result = value.substring(lastIndex + 1);
  storeInCache("prevUnderscore", value, result);
  return result;
};

export const getSpacedString = (value) => {
  const cachedValue = isInCache("space", value);

  if (cachedValue) {
    return cachedValue;
  }
  const result = createJoinedString(value, " ");
  storeInCache("space", value, result);
  return result;
};

export const generateTableHeading = (...value) => {
  if (value.length === 0) {
    return "---";
  }
  let result = [];
  for (let j = 0; j < value.length; j++) {
    const cachedValue = isInCache("heading", value[j]);

    if (cachedValue) {
      result.push(cachedValue);
      continue;
    }

    const res = createJoinedString(value[j]);
    storeInCache("heading", value[j], res);

    result.push(res);
  }

  return result;
};

export const getSearchParams = (url = "", key) => {
  if (!url) {
    return null;
  }

  const queryParams = new URL(url).search;
  const searchData = new URLSearchParams(queryParams);
  return searchData.has(key) ? searchData.get(key) : null;
};

export const generateCertificateId = ({
  name,
  edition,
  year,
  subject,
  countOfRank,
  rank,
}) => {
  let id = "" + name;

  const newEdition = String(edition);

  if (newEdition.length < 3) {
    let zeros = 3 - newEdition.length;
    while (zeros > 0) {
      id += "0";
      zeros--;
    }
  }

  id += String(edition);
  id += String(year);

  id += getSubjectCode(subject);

  id += String(countOfRank);

  const newRank = String(rank);

  if (newRank.length < 6) {
    let zeros = 6 - newRank.length;
    while (zeros > 0) {
      id += "0";
      zeros--;
    }
  }

  id += String(rank);

  return id;
};

export const getSubjectCode = (subject) => {
  switch (subject) {
    case "total":
      return "00";
    case "react":
      return "01";
    case "cs":
      return "02";
    case "ml":
      return "03";
    case "dbms":
      return "04";
    default:
      return "";
  }
};

export const arrayToString = (array = []) => {
  if (array.length === 0) {
    return "";
  }
  const itemType = typeof array[0];
  if (itemType === "string") {
    return array.join(", ");
  }

  if (itemType === "object") {
    let result = "";
    array.forEach((arrItem) => {
      Object.keys(arrItem).forEach((key) => {
        const item = arrItem[key];
        result += `
${key}: ${item}`;
      });
      result += `
-------------`;
    });
    return result;
  }
};

export const objectToString = (obj = {}) => {
  let result = "";

  Object.keys(obj).forEach((key) => {
    const item = obj[key];
    result += `
${key}: ${item}`;
  });

  return result;
};

export const isLink = (url) => {
  if (!url) {
    return false;
  }

  try {
    const urlData = new URL(url);
    return urlData.origin === "null" ? false : true;
  } catch (err) {
    return false;
  }
};

export const debounce = (callback, timeout = 500) => {
  let id = null;
  return (...args) => {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

export const covertStringToBoolean = (value) => {
  return value === "true" ? true : false;
};

export * from "./reducer";
export * from "./drawer";

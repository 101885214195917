import {
  GET_CERTIFICATE_RECORDS,
  CHANGE_SELECTED_SUBJECT
} from "../../Actions";

const initialState = {
  data: {},
  relation: {},
  columns: [],
  onceFetched: false,
  /**
   * ==== relationAttributes====
   * contains all the attributes of their respective category
   * using map to maintain insertion order
   */

  relationAttributes: new Map(),
  excludedRelation: { isb: true },
  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1
  },
  selectedSubject: ""
};

const viewCertificate = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_CERTIFICATE_RECORDS}_SUCCESS`: {
      const {
        data,
        relation,
        columns,
        relationAttributes,
        paginationData
      } = payload;

      return {
        ...store,
        data,
        relation,
        columns,
        relationAttributes,
        paginationData,
        onceFetched: true
      };
    }

    case "CLEAR_VIEW_CERTIFICATE_DATA": {
      return {
        ...store,
        data: {},
        relation: {},
        columns: [],
        onceFetched: false,
        relationAttributes: new Map(),
        excludedRelation: { isb: true },
        paginationData: {
          hasNextPage: true,
          hasPrevPage: null,
          prevPageNumber: -1,
          currentPage: 1,
          nextPageNumber: -1,
          currentPageSize: 0,
          totalPages: 1
        }
      };
    }

    case CHANGE_SELECTED_SUBJECT: {
      const { subject } = payload;
      return {
        ...store,
        selectedSubject: subject
      };
    }
    default:
      return store;
  }
};

export default viewCertificate;

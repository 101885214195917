import { searchRoutes } from "./SearchRoutes";
import { participantRoutes } from "./ParticipantRoutes";
import { ambassadorRoutes } from "./AmbassadorRoutes";
import { announcementRoutes } from "./AnnouncementRoutes";
import { certificateRoutes } from "./CertificateRoutes";
import { leaderboardRoutes } from "./LeaderboardRoutes";
import { userRoutes } from "./UserRoutes";
import { mailRoutes } from "./MailRoutes";
import { notificationRoutes } from "./NotificationRoutes";
import { refundsRoutes } from "./RefundsRoutes";
import { studyMaterialRoutes } from "./StudyMaterialRoutes";
import { supportRoutes } from "./SupportRoutes";
import { voucherRoutes } from "./VoucherRoutes";
import { paymentRoutes } from "./PaymentRoutes";
import { discountsRoutes } from "./DiscountsRoutes";
import { seminarRoutes } from "./SeminarRoutes";

const Routes = {
  unAuthorized: {
    loginRoute: {
      path: "/",
    },
    versionRoute: {
      path: "/isb_version",
    },
    forgotPasswordRoute: {
      path: "/forgot_password",
    },
    resetPasswordRoute: {
      path: "/reset_password",
    },
  },

  authorized: {
    ...searchRoutes,
    ...participantRoutes,
    ...ambassadorRoutes,
    ...announcementRoutes,
    ...certificateRoutes,
    ...leaderboardRoutes,
    ...userRoutes,
    ...mailRoutes,
    ...notificationRoutes,
    ...refundsRoutes,
    ...studyMaterialRoutes,
    ...supportRoutes,
    ...voucherRoutes,
    ...paymentRoutes,
    ...discountsRoutes,
    ...seminarRoutes,
  },
};

export default Routes;

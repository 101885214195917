import {
  GET_EMAILS,
  UPDATE_MAIL,
  DELETE_MAIL,
  CHANGE_EMAIL_CATEGORY,
  GET_REGISTRATIONS_EMAIL,
  UPDATE_REGISTRATIONS_EMAIL,
  DELETE_REGISTRATIONS_EMAIL,
  CHANGE_EDIT_EMAIL_CATEGORY,
} from "../Actions";

const initialStore = {
  emails: {},
  selectedEmailId: "",
  selectedEditEmailId: "",
  dataFetched: false,
  registrationEmailsData: {},
  registrationEmailRelation: {},
};

const mail = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_EMAILS}_SUCCESS`: {
      const { data = [] } = payload;
      const result = {};
      data.forEach((item) => {
        result[item.id] = {
          ...item,
        };
      });
      return {
        ...store,
        emails: result,
        dataFetched: true,
      };
    }

    case `${GET_REGISTRATIONS_EMAIL}_SUCCESS`: {
      const { data = [], included = [] } = payload;
      const newData = {};
      const relations = {};

      data.forEach((item) => {
        newData[item.id] = {
          ...item,
        };
      });

      included.forEach((item) => {
        const key = `${item.id}_${item.type}`;
        relations[key] = {
          ...(item.attributes || {}),
        };
      });

      return {
        ...store,
        registrationEmailsData: newData,
        registrationEmailRelation: relations,
      };
    }

    case `${UPDATE_REGISTRATIONS_EMAIL}_SUCCESS`: {
      const { data = {}, included = [] } = payload;
      const newData = { ...store.registrationEmailsData };
      const relations = { ...store.registrationEmailRelation };

      newData[data.id] = {
        ...data,
      };

      included.forEach((item) => {
        const key = `${item.id}_${item.type}`;
        relations[key] = {
          ...(item.attributes || {}),
        };
      });

      return {
        ...store,
        registrationEmailsData: newData,
        registrationEmailRelation: relations,
      };
    }

    case `${DELETE_REGISTRATIONS_EMAIL}_SUCCESS`: {
      const { data = {} } = payload;

      const newData = { ...store.registrationEmailsData };
      Reflect.deleteProperty(newData, data.id);

      const newRelation = { ...store.registrationEmailRelation };
      const { id, type } = data.relationships.emailSaver.data;
      const key = `${id}_${type}`;
      Reflect.deleteProperty(newRelation, key);

      return {
        ...store,
        registrationEmailsData: newData,
        registrationEmailRelation: newRelation,
      };
    }

    case CHANGE_EMAIL_CATEGORY: {
      const { id } = payload;

      return {
        ...store,
        selectedEmailId: id,
      };
    }

    case CHANGE_EDIT_EMAIL_CATEGORY: {
      const { id } = payload;

      return {
        ...store,
        selectedEditEmailId: id,
      };
    }

    case `${UPDATE_MAIL}_SUCCESS`: {
      const { data } = payload;
      const newEmails = { ...store.emails };
      newEmails[data.id] = { ...data };
      return {
        ...store,
        emails: newEmails,
      };
    }

    case `${DELETE_MAIL}_SUCCESS`: {
      const { data } = payload;
      const newEmails = { ...store.emails };

      Reflect.deleteProperty(newEmails, data.id);
      return {
        ...store,
        emails: newEmails,
      };
    }

    default: {
      return store;
    }
  }
};

export default mail;

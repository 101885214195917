import {
  GET_UNSUBSCRIBE_MAIL,
  CREATE_UNSUBSCRIBE_MAIL,
  SEARCH_UNSUBSCRIBE_MAIL,
  UPDATE_EMAIL_SEARCH_QUERY,
} from "../Actions";
import { createInitialPageStore } from "../helpers/reducer";
import { union } from "lodash";

const initialStore = {
  emailQuery: null,
  data: {},
  relation: {},
  dataIds: [],
  onceFetched: false,

  paginationData: {
    hasNextPage: true,
    hasPrevPage: null,
    prevPageNumber: -1,
    currentPage: 1,
    nextPageNumber: -1,
    currentPageSize: 0,
    totalPages: 1,
  },
};

const unsubscribeMail = (store = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${SEARCH_UNSUBSCRIBE_MAIL}_SUCCESS`:
    case `${GET_UNSUBSCRIBE_MAIL}_SUCCESS`: {
      const { data, relation, dataIds, paginationData } = payload;

      return {
        ...store,
        dataIds,
        data,
        relation,
        paginationData,
        onceFetched: true,
      };
    }

    case `${CREATE_UNSUBSCRIBE_MAIL}_SUCCESS`: {
      const { data, dataIds } = payload;
      const newData = { ...store.data, ...data };
      const newIds = union(store.dataIds, dataIds);
      return {
        ...store,
        data: newData,
        dataIds: newIds,
      };
    }

    case UPDATE_EMAIL_SEARCH_QUERY: {
      return {
        ...store,
        emailQuery: payload.query,
      };
    }

    default: {
      return store;
    }
  }
};

export default unsubscribeMail;

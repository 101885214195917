import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import DrawerHead from "./DrawerHead";
import DrawerItem from "./DrawerItem";
import UserTab from "./Tabs/UserTab";
import MailTab from "./Tabs/MailTab";
import AnnouncementTab from "./Tabs/AnnouncementTab";
import SearchTab from "./Tabs/SearchTab";
import VouchersTab from "./Tabs/VouchersTab";
import StudyMaterialTab from "./Tabs/StudyMaterialTab";
import AmbassadorTab from "./Tabs/AmbassadorTab";
import RefundsTab from "./Tabs/RefundsTab";
import ParticipantTab from "./Tabs/ParticipantTab";
import SupportTab from "./Tabs/SupportTab";
import LeaderBoardTab from "./Tabs/LeaderBoard";
import CertificateTab from "./Tabs/CertificateTab";
import PaymentTab from "./Tabs/PaymentTab";
import DiscountsTab from "./Tabs/DiscountsTab";
import SeminarTab from "./Tabs/SeminarTab";

import useTransition from "../../hooks/useTransition";
import useLogout from "../../hooks/useLogout";
import useDimensions from "../../hooks/useDimensions";

import PropTypes from "prop-types";

import styles from "./Drawer.module.css";

const Drawer = ({
  history,
  toggleDrawer,
  drawerActive,
  location,
  userData,
}) => {
  const { isVisible } = useTransition({ active: drawerActive, maxWait: 320 });
  const { viewStatus } = useDimensions();
  const { logout } = useLogout({
    onFailed: !viewStatus.isdesktopView ? toggleDrawer : null,
  });

  const { name, email } = userData;
  return isVisible ? (
    <div className={styles.container}>
      <div
        onClick={toggleDrawer}
        role="button"
        className={`${styles.overlay} ${
          drawerActive ? styles.active_overlay : styles.de_active_overlay
        }`}
      />

      <div
        className={`${styles.content} ${
          drawerActive ? styles.slideIn : styles.slideOut
        }`}
      >
        <DrawerHead email={email} userName={name} />

        <SearchTab location={location} />
        <ParticipantTab location={location} />
        <SupportTab location={location} />
        <LeaderBoardTab location={location} />
        <UserTab location={location} />
        <SeminarTab location={location} />
        <MailTab location={location} />
        <DiscountsTab location={location} />
        <VouchersTab location={location} />
        <RefundsTab location={location} />
        <AmbassadorTab location={location} />
        <AnnouncementTab location={location} />
        <StudyMaterialTab location={location} />
        <CertificateTab location={location} />
        <PaymentTab location={location} />

        <DrawerItem
          onClick={logout}
          title="Logout"
          icon="fas fa-sign-out-alt"
          isLink={false}
        />
      </div>
    </div>
  ) : null;
};

Drawer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isbData: PropTypes.object.isRequired,
  }).isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  drawerActive: PropTypes.bool.isRequired,
};

const mapStateToProps = (store, props) => {
  return {
    userData: store.auth.userData,
  };
};

export default connect(mapStateToProps, null)(withRouter(Drawer));

import React from "react";

export const createDrawerItems = (routes, location, Component) => {
  return Object.keys(routes).map((key) => {
    const { path, drawerTitle, drawerIcon, accessType } = routes[key];
    return (
      <Component
        key={path}
        currentPath={location.pathname}
        path={path}
        title={drawerTitle}
        icon={drawerIcon}
        accessType={accessType}
      />
    );
  });
};
